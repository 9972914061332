export default async function getJobs(
  token,
  status,
  page,
  perPage,
  search,
  sortField,
  sortDirection,
) {
  if (token) {
    const searchQuery = `?status=${status}&page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&search=${JSON.stringify(
      search,
    )}`;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/jobs${searchQuery}        
        `,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.ok) {
        const jsonData = await response.json();
        const jobResponse = { totalRows: jsonData.TotalRows };
        if (jsonData.Jobs) {
          const jobs = jsonData.Jobs.map((job) => {
            return {
              orderNumber: job.OrderNumber,
              jobUuid: job.JobUuid,
              cityFrom: job.CityFrom,
              countryFrom: job.CountryFrom,
              cityTo: job.CityTo,
              zipCodeFrom: job.ZipCodeFrom,
              zipCodeTo: job.ZipCodeTo,
              countryTo: job.CountryTo,
              dateCreated: job.DateCreated
                ? new Date(job.DateCreated).toLocaleDateString('nl-NL', { timeZone: 'UTC' })
                : '',
              status: job.Status,
              isOwner: job.IsOwner,
              readyFrom: job.ReadyFrom,
              price: job.Price,
              pickupDate: job.PickupDate,
              deliveryDate: job.DeliveryDate,
              distance: job.Distance,
              amountOfVehicles: job.AmountOfVehicles,
              amountOfStandard: job.AmountOfStandard,
              amountOfSuv: job.AmountOfSuv,
              amountOfVanSmall: job.AmountOfVanSmall,
              amountOfVanLarge: job.AmountOfVanLarge,
              vehicles: job.VehiclesJson,
              additionalInfo: job.AdditionalInformation,
              areDriverDocumentsRequired: job.AreDriverDocumentsRequired,
            };
          });
          jobResponse.jobs = jobs;
        }

        return jobResponse;
      }
    } catch (e) {
      console.error(e);
      return { totalRows: 0 };
    }
  }
  return null;
}
