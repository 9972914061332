import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { regex } from '../../../util/regex';

export default function CompanyInfo(props) {
  const { countries, showValidation, onChange, defaultLength, formData } = props;

  const getCountryOptions = () => {
    return countries.map((country) => {
      return (
        <option key={country.code} value={country.name}>
          {country.name}
        </option>
      );
    });
  };

  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Company info</h2>
      <h6>We need additional information to complete your order</h6>
      <Row>
        <Col>
          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Company name*</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.name ?? ''}
                  onChange={onChange}
                  required
                  isInvalid={showValidation && !formData.name}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={8}>
              <Form.Group controlId="street">
                <Form.Label>Street*</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.street ?? ''}
                  onChange={onChange}
                  required
                  isInvalid={showValidation && !formData.street}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="houseNumber">
                <Form.Label>Number*</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.houseNumber ?? ''}
                  onChange={onChange}
                  required
                  isInvalid={showValidation && !formData.houseNumber}
                  maxLength={20}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="zipcode">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control type="text" value={formData.zipcode} onChange={onChange} />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>City*</Form.Label>
              <Form.Control
                type="text"
                value={formData.city ?? ''}
                onChange={onChange}
                required
                isInvalid={showValidation && !formData.city}
                maxLength={20}
              />
            </Form.Group>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form.Group controlId="country">
                <Form.Label>Country*</Form.Label>
                <Form.Select
                  onChange={onChange}
                  isInvalid={showValidation && formData.country === ''}
                  aria-label="country"
                  value={formData.country ?? ''}
                >
                  {getCountryOptions()}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="vatNumber">
                <Form.Label>VAT Number*</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.vatNumber ?? ''}
                  onChange={onChange}
                  required
                  isInvalid={showValidation && !formData.vatNumber}
                  maxLength={20}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email address*</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.email ?? ''}
                  onChange={onChange}
                  required
                  isInvalid={
                    showValidation && (formData.email === '' || !formData.email.match(regex))
                  }
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
