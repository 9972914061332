import decapitalizeArray from '../../util/decapitalizeArray';
import getWeekSchedule from './util/weekSchedule';

export default async function getJob(token, jobUuid) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/jobs/${jobUuid}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const jsonData = await response.json();
      if (response.ok) {
        const verhiclesForMapping = await decapitalizeArray(jsonData.Vehicles);
        let openingHoursFrom = await decapitalizeArray(jsonData.CompanyFrom.OpeningTimes);
        let openingHoursTo = await decapitalizeArray(jsonData.CompanyTo.OpeningTimes);
        const documents = await decapitalizeArray(jsonData.Documents);

        if (openingHoursFrom.length === 0) {
          openingHoursFrom = getWeekSchedule();
        }
        if (openingHoursTo.length === 0) {
          openingHoursTo = getWeekSchedule();
        }

        const job = {
          id: jsonData.JobUuid,
          orderNumber: jsonData.OrderNumber,
          status: jsonData.Status,
          vehicles: verhiclesForMapping,
          readyFrom: jsonData.ReadyFrom,
          additionalInfo: jsonData.AdditionalInformation ?? '',
          price: jsonData.Price,
          distance: jsonData.Distance,
          createdDate: jsonData.DateCreated,
          pickupDate: jsonData.PickupDate,
          deliveryDate: jsonData.DeliveryDate,
          commentTransporter: jsonData.CommentTransporter,
          commentClient: jsonData.CommentClient,
          areDriverDocumentsRequired: jsonData.AreDriverDocumentsRequired,
          companyFrom: {
            name: jsonData.CompanyFrom.Name,
            street: jsonData.CompanyFrom.Street,
            houseNumber: jsonData.CompanyFrom.HouseNumber,
            zipcode: jsonData.CompanyFrom.Zipcode,
            city: jsonData.CompanyFrom.City ?? jsonData.CityFrom,
            country: jsonData.CompanyFrom.Country ?? jsonData.CountryFrom,
            email: jsonData.CompanyFrom.Email ?? '',
            language: jsonData.CompanyFrom.Language ?? 'Nederlands',
            contactPerson: jsonData.CompanyFrom.ContactPerson,
            telephone: jsonData.CompanyFrom.Telephone,
            openingHours: openingHoursFrom,
          },
          companyTo: {
            name: jsonData.CompanyTo.Name,
            street: jsonData.CompanyTo.Street,
            houseNumber: jsonData.CompanyTo.HouseNumber,
            zipcode: jsonData.CompanyTo.Zipcode,
            city: jsonData.CompanyTo.City ?? jsonData.CityTo,
            country: jsonData.CompanyTo.Country ?? jsonData.CountryTo,
            email: jsonData.CompanyTo.Email ?? '',
            language: jsonData.CompanyTo.Language ?? 'Nederlands',
            contactPerson: jsonData.CompanyTo.ContactPerson,
            telephone: jsonData.CompanyTo.Telephone,
            openingHours: openingHoursTo,
          },
          transporter: {
            name: jsonData.Transporter.Name,
            id: jsonData.Transporter.UserId,
            companyName: jsonData.Transporter.CompanyName,
            companyId: jsonData.Transporter.CompanyId,
          },
          driver: {
            name: jsonData.Drivers[0]?.Name,
            lastName: jsonData.Drivers[0]?.LastName,
            id: jsonData.Drivers[0]?.DriverId,
            licensePlate: jsonData.LicenseplateTransporter,
          },
          documents,
        };
        return job;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
