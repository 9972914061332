import React from 'react';
import { Form } from 'react-bootstrap';

const style = { maxWidth: 100 };

export default function TimeDropdown(props) {
  const { weekSchedule, onChange, formDataType } = props;

  const startTime = 7;
  const endTime = 22;
  const interval = 30;

  const generateTimeOptions = (day, postFix) => {
    const options = [];
    for (let hour = startTime; hour <= endTime; hour += 1) {
      for (let minute = 0; minute < 60; minute += interval) {
        if (hour === 22 && minute === 30) {
          break;
        }
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(
          <option key={`${time}-${day.dayNumber}-${postFix}-${formDataType}`} value={time ?? ''}>
            {time}
          </option>,
        );
      }
    }
    return options;
  };

  const generateFromOptions = (day) => {
    return generateTimeOptions(day, 'minFrom');
  };

  const generateToOptions = (day) => {
    return generateTimeOptions(day, 'maxTill');
  };

  const onChangeCheckbox = (day) => {
    const updatedSchedule = [...weekSchedule];
    const updatedDay = updatedSchedule.find((x) => x.dayNumber === day.dayNumber);
    updatedDay.isClosed = !day.isClosed;
    onChange(updatedSchedule, formDataType);
  };

  const onChangeTime = (day, time, type) => {
    const updatedSchedule = [...weekSchedule];
    const updatedDay = updatedSchedule.find((x) => x.dayNumber === day.dayNumber);
    if (type === 'from') {
      updatedDay.from = time;
    } else {
      updatedDay.till = time;
    }
    onChange(updatedSchedule, formDataType);
  };

  const getDays = () => {
    return weekSchedule.map((day) => {
      return (
        <div
          key={day.dayNumber}
          style={{
            width: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 10,
            marginBottom: 5,
          }}
        >
          <Form.Check
            type="switch"
            id="closed"
            key={`${day.dayNumber}-${formDataType}`}
            checked={!day.isClosed ?? true}
            onChange={() => {
              onChangeCheckbox(day);
            }}
          />

          <div style={{ width: 100 }}>{day.name}</div>
          {day.isClosed && <div>Closed</div>}
          {!day.isClosed && (
            <>
              <Form.Control
                as="select"
                style={style}
                value={day.from ?? ''}
                onChange={(e) => onChangeTime(day, e.target.value, 'from')}
              >
                {generateFromOptions(day)}
              </Form.Control>
              <Form.Control
                as="select"
                style={style}
                value={day.till ?? ''}
                onChange={(e) => onChangeTime(day, e.target.value, 'till')}
              >
                {generateToOptions(day)}
              </Form.Control>
            </>
          )}
        </div>
      );
    });
  };

  return <div>{weekSchedule && <>{getDays()}</>}</div>;
}
