import React from 'react';
import Logo from '../assets/loader.gif';

export default function Loader(props) {
  const { isLoading } = props;

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Logo} alt="loading" style={{ height: 80, width: 80 }} />
        </div>
      )}
    </div>
  );
}
