import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectObjectsComponent(props) {
  const { data, onChange, value } = props;
  const getDropdownItems = () => {
    if (data) {
      return data.map((item) => {
        return (
          <option value={item.key} key={item.key}>
            {item.value}
          </option>
        );
      });
    }
    return <div />;
  };

  return (
    <Form.Select onChange={(e) => onChange(e.target.value)} value={value}>
      {getDropdownItems()}
    </Form.Select>
  );
}
