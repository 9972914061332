import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getJobsCount from '../../api/jobs/getJobsCount';
import { ReactComponent as CalculatorLogo } from '../../assets/calculator.svg';
import Jobs from '../../components/jobs';
import Header from './components/header';
import JobTabs from './components/jobTabs';
import useUserStore from '../../setup/stores/userStore'; // Import from user store

export default function HomeClient() {
  const [activeTab, setActiveTab] = useState('ToPlan');
  const [jobCount, setJobCount] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const isBlocked = useUserStore((state) => state.isBlocked);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const fetchJobCount = async () => {
    const token = await getAccessTokenSilently();
    const response = await getJobsCount(token);
    if (response) {
      setJobCount(response);
    }
  };

  const handleJobChange = () => {
    fetchJobCount();
  };

  useEffect(() => {
    fetchJobCount();
  }, []);

  return (
    <div>
      <Header
        first={t('homeClient.title')}
        component={
          <JobTabs activeTab={activeTab} onTabChange={handleTabChange} jobCount={jobCount} />
        }
        button={
          !isBlocked && (
            <Button onClick={() => navigate('/calculation')}>
              New calculation
              <CalculatorLogo className="button-icon-right" />
            </Button>
          )
        }
      />
      <Container>
        {activeTab === 'Draft' && (
          <Jobs
            status="Draft"
            title="Draft"
            jobCount={jobCount.totalRowsDraft}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'ToPlan' && (
          <Jobs
            status="ToPlan"
            title="To plan"
            jobCount={jobCount.totalRowsToPlan}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Planned' && (
          <Jobs
            status="Planned"
            title="Planned"
            jobCount={jobCount.totalRowsPlanned}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Loaded' && (
          <Jobs
            status="Loaded"
            title="Loaded"
            jobCount={jobCount.totalRowsLoaded}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Unloaded' && (
          <Jobs
            status="Unloaded"
            title="Unloaded"
            jobCount={jobCount.totalRowsUnloaded}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Closed' && (
          <Jobs
            status="Closed"
            title="Closed"
            jobCount={jobCount.TotalRowsClosed}
            onJobChange={handleJobChange}
          />
        )}
      </Container>
    </div>
  );
}
