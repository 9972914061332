export default function getJobTagColor(jobStatus) {
  if (jobStatus === 'Draft') return 'tag-grey';
  if (jobStatus === 'ToPlan') return 'tag-orange';
  if (jobStatus === 'Planned') return 'tag-green';
  if (jobStatus === 'Loaded') return 'tag-blue';
  if (jobStatus === 'Unloaded') return 'tag-blue';
  if (jobStatus === 'Cancelled') return 'tag-red';
  if (jobStatus === 'Closed') return 'tag-black';
  return 'tag-grey';
}
