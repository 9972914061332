import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FaRegEdit } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Popup from './openingHoursModal';
import { isValid } from '../util/isPickupDateValid';

const style = {
  link: {
    paddingLeft: 0,
    textDecoration: 'none',
    fontWeight: 400,
  },
  displayNone: {
    display: 'none',
  },
  display: {
    display: 'block',
  },
};

export default function TransportDetails(props) {
  const { isEditable, isReadOnly, onSave, formData, isClient } = props;
  const [showModal, setShowModal] = useState(false);
  const [openingHours, setOpeningHours] = useState([]);
  const [editPickupDate, setEditPickupDate] = useState(false);
  const [editDeliveryDate, setEditDeliveryDate] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [updatedPickupDate, setUpdatedPickupDate] = useState('');
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = useState(formData.deliveryDate);

  const onOpeningHoursClick = (hours) => {
    setOpeningHours(hours);
    setShowModal(true);
  };

  const handleEditPickupDate = () => {
    setEditPickupDate(true);
    setEditDeliveryDate(false);
  };

  const handleEditDeliveryDate = () => {
    setEditDeliveryDate(true);
    setEditPickupDate(false);
  };

  const handleChangePickupDate = (e) => {
    setUpdatedPickupDate(e.target.value);
  };

  const handleChangeDeliveryDate = (e) => {
    setUpdatedDeliveryDate(e.target.value);
  };

  const handleCancel = () => {
    setEditPickupDate(false);
    setEditDeliveryDate(false);
    setShowValidation(false);
  };

  const handleSave = (pickupDate, deliveryDate, action) => {
    if (isValid(pickupDate, deliveryDate, formData.readyFrom)) {
      setEditPickupDate(false);
      setEditDeliveryDate(false);
      setShowValidation(false);
      formData.pickupDate = pickupDate;
      formData.deliveryDate = deliveryDate;
      onSave(action);
    } else {
      setShowValidation(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const getDeliveryDateTitle = () => {
    if (
      formData.status === 'Draft' ||
      formData.status === 'ToPlan' ||
      formData.status === 'Planned' ||
      formData.status === 'Loaded'
    ) {
      return 'Planned delivery date';
    }
    return 'Delivery date';
  };

  const showElement = () => {
    return isClient || formData.status !== 'ToPlan';
  };

  return (
    <div>
      {openingHours.length > 0 && (
        <Popup show={showModal} onClose={handleModalClose} openingHours={openingHours} />
      )}
      <h3 className="mb-4">Transport details</h3>
      <div className="light-background p-3">
        <Table className="table-borderless darker-color mb-0">
          <thead>
            <tr>
              {formData.pickupDate && (
                <th className="w-50 pb-0">
                  {formData.status === 'Planned' ? 'Planned pickup date' : 'Pickup date'}
                </th>
              )}
              {formData.deliveryDate && <th className="pb-0">{getDeliveryDateTitle()}</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              {formData.pickupDate && (
                <td className="pt-0">
                  <div style={editPickupDate ? style.display : style.displayNone}>
                    <Form.Control
                      type="date"
                      required
                      style={{ height: 39 }}
                      onChange={handleChangePickupDate}
                      isInvalid={
                        showValidation &&
                        !isValid(updatedPickupDate, formData.deliveryDate, formData.readyFrom)
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 8,
                        marginTop: 4,
                        marginBottom: -28,
                      }}
                    >
                      <Button variant="inverse" size="sm" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          handleSave(updatedPickupDate, formData.deliveryDate, 'pickupDate');
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  <div style={editPickupDate ? style.displayNone : style.display}>
                    {new Date(formData.pickupDate).toLocaleDateString('nl-NL')}
                    <FaRegEdit
                      visibility={isEditable && !isReadOnly ? 'visible' : 'hidden'}
                      className="primary-color pb-1 ps-2 fs-4"
                      onClick={handleEditPickupDate}
                    />
                  </div>
                </td>
              )}
              {formData.deliveryDate && (
                <td className="pt-0">
                  <div style={editDeliveryDate ? style.display : style.displayNone}>
                    <Form.Control
                      type="date"
                      required
                      style={{ height: 39 }}
                      onChange={handleChangeDeliveryDate}
                      isInvalid={
                        showValidation &&
                        !isValid(formData.pickupDate, updatedDeliveryDate, formData.readyFrom)
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 8,
                        marginTop: 4,
                        marginBottom: -28,
                      }}
                    >
                      <Button variant="inverse" size="sm" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          handleSave(formData.pickupDate, updatedDeliveryDate, 'deliveryDate');
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  <div style={editDeliveryDate ? style.displayNone : style.display}>
                    {new Date(formData.deliveryDate).toLocaleDateString('nl-NL', {
                      timeZone: 'UTC',
                    })}
                    <FaRegEdit
                      visibility={isEditable && !isReadOnly ? 'visible' : 'hidden'}
                      className="primary-color pb-1 ps-2 fs-4"
                      onClick={handleEditDeliveryDate}
                    />
                  </div>
                </td>
              )}
            </tr>
          </tbody>
          <thead>
            <tr>
              <th className="w-50 pt-3 pb-1">Pickup address</th>
              <th className="pb-1">Delivery address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-0">
                {formData.companyFrom.name && showElement() && (
                  <div>{`${formData.companyFrom.name} `}</div>
                )}
                {showElement() && (
                  <div>{`${formData.companyFrom.street} ${formData.companyFrom.houseNumber}`}</div>
                )}
                <div>{`${formData.companyFrom.zipcode} ${formData.companyFrom.city}`}</div>
                <div>{`${formData.companyFrom.country}`}</div>
                <Button
                  style={style.link}
                  className="primary-color"
                  onClick={() => onOpeningHoursClick(formData.companyFrom.openingHours)}
                  variant="link"
                >
                  Opening hours
                </Button>
              </td>
              <td className="py-0">
                {formData.companyTo.name && showElement() && (
                  <div>{`${formData.companyTo.name} `}</div>
                )}
                {showElement() && (
                  <div>{`${formData.companyTo.street} ${formData.companyTo.houseNumber}`}</div>
                )}
                <div>{`${formData.companyTo.zipcode} ${formData.companyTo.city}`}</div>
                <div>{`${formData.companyTo.country}`}</div>
                <Button
                  style={style.link}
                  className="primary-color"
                  onClick={() => onOpeningHoursClick(formData.companyTo.openingHours)}
                  variant="link"
                >
                  Opening hours
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}
