export default async function postJobDocument(token, jobId, file) {
  const formData = new FormData();
  formData.append('File', file);
  formData.append('JobId', jobId);
  formData.append('FileName', file.name);
  formData.append('DocumentType', file.documentType);

  if (token) {
    try {
      const response = await fetch(`${process.env.REACT_APP_MENDIX_HOST}/postDocument`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      return response.ok;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
