import React from 'react';
import { ReactComponent as TruckLogo } from '../../../assets/truck.svg';

const style = {
  wizardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 700,
  },
  stepIcon: {
    borderRadius: 50,
    width: 15,
    height: 15,
    border: 'solid',
    borderWidth: 1,
  },

  lineContainer: { display: 'flex', flex: 1, height: 1, marginTop: 20 },
  line: { height: 1, flex: 1 },
};

function Wizard(props) {
  const { step } = props;

  const getStepClass = (ownStep) => {
    if (step === ownStep) {
      return 'primary-color';
    }
    if (step < ownStep) {
      return 'default-color';
    }
    return 'secondary-color';
  };

  const getStepIconClass = (ownStep) => {
    if (step > ownStep) {
      return 'primary-background primary-border';
    }
    return 'light-border';
  };

  return (
    <div style={style.wizardContainer}>
      <div style={style.stepContainer}>
        <div className={getStepClass(1)}>Locations</div>
        {step === 1 && <TruckLogo />}
        {step !== 1 && <div style={style.stepIcon} className={getStepIconClass(1)} />}
      </div>
      <div style={style.lineContainer}>
        <div style={style.line} className="default-background" />
      </div>
      <div style={style.stepContainer}>
        <div className={getStepClass(2)}>Add vehicles</div>
        {step === 2 && <TruckLogo />}
        {step !== 2 && <div style={style.stepIcon} className={getStepIconClass(2)} />}
      </div>
      <div style={style.lineContainer}>
        <div style={style.line} className="default-background" />
      </div>
      <div style={style.stepContainer}>
        <div className={getStepClass(3)}>Calculation</div>
        {step === 3 && <TruckLogo />}
        {step !== 3 && <div style={style.stepIcon} className={getStepIconClass(3)} />}
      </div>
    </div>
  );
}

export default Wizard;
