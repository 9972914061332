import getCurrentDateOrDateInFuture from '../../../util/getCurrentDateOrDateInFuture';
import { isDateAfterDate } from '../../../util/isDateAfterDate';
import { isDateWithinXdaysFromDate } from '../../../util/isDateWithinXDays';
// eslint-disable-next-line import/prefer-default-export
export const isValid = (formData, job) => {
  let isDataValid = true;

  const dateForCompare = getCurrentDateOrDateInFuture(job.readyFrom);
  formData.isPickupDateValid = true;

  if (!formData.pickupDate) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }

  if (!formData.isAccepted) {
    isDataValid = false;
  }

  if (!isDateAfterDate(formData.pickupDate, new Date().setUTCHours(0, 0, 0, 0))) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }

  if (!isDateAfterDate(job.readyFrom, formData.pickupDate)) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }

  if (!isDateWithinXdaysFromDate(dateForCompare, formData.pickupDate, 6)) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }

  return isDataValid;
};
