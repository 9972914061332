import React from 'react';
import Autocomplete from 'react-google-autocomplete';

export default function CityDropdown(props) {
  const { countryFilter, disabled, onPlaceSelected, classes } = props;

  const getCity = (place) => {
    const city = { name: '', level: '' };
    const places = place.address_components.flatMap((component) => {
      if (
        component.types.includes('locality') ||
        component.types.includes('postal_town') ||
        component.types.includes('administrative_area_level_2') ||
        component.types.includes('administrative_area_level_3') ||
        component.types.includes('sublocality')
      ) {
        return [component];
      }
      return [];
    });

    places.forEach((placeItem) => {
      if (placeItem.types.includes('locality')) {
        city.name = placeItem.long_name;
        city.level = 'locality';
      }
      if (placeItem.types.includes('postal_town') && city.level !== 'locality') {
        city.name = placeItem.long_name;
        city.level = 'postal_town';
      }
      if (
        placeItem.types.includes('administrative_area_level_3') &&
        city.level !== 'locality' &&
        city.level !== 'postal_town'
      ) {
        city.name = placeItem.long_name;
        city.level = 'administrative_area_level_3';
      }
      if (
        placeItem.types.includes('sublocality') &&
        city.level !== 'locality' &&
        city.level !== 'postal_town' &&
        city.level !== 'administrative_area_level_3'
      ) {
        city.name = placeItem.long_name;
        city.level = 'sublocality';
      }
      if (
        placeItem.types.includes('administrative_area_level_2') &&
        city.level !== 'locality' &&
        city.level !== 'postal_town' &&
        city.level !== 'administrative_area_level_3' &&
        city.level !== 'sublocality'
      ) {
        city.name = placeItem.long_name;
        city.level = 'administrative_area_level_2';
      }
    });
    return city.name;
  };

  return (
    <Autocomplete
      debounce={300}
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      className={`form-control select ${classes}`}
      onPlaceSelected={(place) => {
        const cityName = getCity(place);
        const item = {
          name: cityName,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        onPlaceSelected(item);
      }}
      options={{
        types: ['postal_code', 'locality', 'postal_town', 'administrative_area_level_3'],
        componentRestrictions: { country: countryFilter || '' },
        fields: ['geometry.location', 'address_components'],
        debounce: 300,
      }}
      defaultValue=""
      placeholder="Zip code or city"
      disabled={disabled}
    />
  );
}
