import getCurrentDateOrDateInFuture from '../../../util/getCurrentDateOrDateInFuture';
import { isDateWithinXdaysFromDate } from '../../../util/isDateWithinXDays';
// eslint-disable-next-line import/prefer-default-export
export const isValid = (pickupDate, deliveryDate, readyFrom) => {
  let isDataValid = true;

  const dateForCompare = getCurrentDateOrDateInFuture(readyFrom);

  if (!pickupDate || new Date(pickupDate) > new Date(deliveryDate) || !deliveryDate) {
    isDataValid = false;
  }

  if (pickupDate && new Date(pickupDate) < new Date(readyFrom)) {
    isDataValid = false;
  }

  if (pickupDate && !isDateWithinXdaysFromDate(dateForCompare, pickupDate, 6)) {
    isDataValid = false;
  }

  if (deliveryDate && !isDateWithinXdaysFromDate(dateForCompare, deliveryDate, 6)) {
    isDataValid = false;
  }

  return isDataValid;
};
