import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import getCurrentDateOrDateInFuture from '../../util/getCurrentDateOrDateInFuture';
import getCompany from '../../api/companies/getCompany';
import getDrivers from '../../api/drivers/getDrivers';
import patchJob from '../../api/jobs/patchJobAccepted';
import CmrInfo from '../../components/cmrInfoPopup';
import Header from '../../components/header';
import jobSummary from '../../components/jobSummary';
import SelectComponent from '../../components/select';
import { useJobStore } from '../../setup/stores/jobStore';
import DriverDetails from '../driver-details/driverDetails';
import { isValid } from './util/isValid';

export default function AcceptJob() {
  const [job] = useJobStore((state) => [state.job]);
  const [formData, setFormData] = useState({
    pickupDate: '',
    deliveryDate: '',
    licensePlateTransporter: '',
    driver: null,
    isDriverValid: true,
    isDriverDocumentValid: true,
    isPickupDateValid: true,
    isDeliveryDateValid: true,
  });
  const [drivers, setDrivers] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAddDriver, setIsAddDriver] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  if (job.id !== id) {
    navigate('/');
  }

  const checkCompany = async () => {
    try {
      const token = await getAccessTokenSilently();
      const companyObject = await getCompany(token);
      if (!companyObject.contactPerson) {
        navigate('/company');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDrivers = async () => {
    const token = await getAccessTokenSilently();
    const driverObjects = await getDrivers(token);
    const driversForSelect = driverObjects.map((driver) => {
      return {
        name: `${driver.name} ${driver.lastName}`,
        id: driver.id,
        documents: driver.documents,
      };
    });
    setDrivers(driversForSelect);
  };

  useEffect(() => {
    checkCompany();
  }, []);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const handleSelectDriver = (driver) => {
    const updatedFormData = { ...formData };

    updatedFormData.driver = driver;
    isValid(updatedFormData, job.readyFrom, job.areDriverDocumentsRequired);
    setFormData(updatedFormData);
  };

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.id]: value,
    });
  };

  const handleSubmit = async () => {
    if (!isValid(formData, job.readyFrom, job.areDriverDocumentsRequired)) {
      setShowValidation(true);
      window.scrollTo(0, 0);
    } else {
      setShowValidation(false);
      const token = await getAccessTokenSilently();
      const response = await patchJob(
        token,
        id,
        formData.pickupDate,
        formData.deliveryDate,
        formData.licensePlateTransporter,
        formData.driver,
      );
      if (response) {
        navigate('/');
      }
    }
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    handleSubmit();
  };

  const handleClickAddDriver = () => {
    setIsAddDriver(true);
  };

  const handleSubmitDriver = async () => {
    setIsAddDriver(false);
    fetchDrivers();
  };

  const readyFromDate = new Date(job.readyFrom);
  const maxDate = getCurrentDateOrDateInFuture(readyFromDate);
  maxDate.setDate(maxDate.getDate() + 6);

  return (
    <div>
      <Header first="Accept" second=" Job" />
      <CmrInfo
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => handleModalConfirm()}
      />
      <Container>
        <Form style={{ maxWidth: 800 }}>
          <h2 style={{ marginTop: 50 }}>{`Accept ${job.orderNumber}`}</h2>
          <Row>
            <Col>
              <h6 className="mb-5">
                {`Pick a pickup date
                 and a delivery date before ${maxDate.toLocaleDateString('nl-NL', {
                   timeZone: 'UTC',
                 })} (max 6 days).`}
              </h6>
              <div className="mb-3">{jobSummary(job)}</div>
              <Form.Group controlId="pickupDate">
                <Form.Label>Pickup date*</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.pickupDate}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !formData.isPickupDateValid}
                  min={new Date().toISOString().split('T')[0]}
                  max={maxDate.toISOString().split('T')[0]}
                />
              </Form.Group>
              <Form.Group controlId="deliveryDate">
                <Form.Label>Delivery date*</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.deliveryDate}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !formData.isDeliveryDateValid}
                  min={new Date().toISOString().split('T')[0]}
                  max={maxDate.toISOString().split('T')[0]}
                />
              </Form.Group>
              <Form.Group controlId="licensePlateTransporter" className="mt-2">
                <Form.Label>License plate transporting truck</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.licensePlateTransporter ?? ''}
                  onChange={handleChange}
                  maxLength={20}
                />
              </Form.Group>
              <Form.Group controlId="formDriver" className="mt-2">
                <Form.Label>Driver*</Form.Label>
                <div style={{ display: 'flex', alignItems: 'center', gap: 50 }}>
                  <div style={{ flex: 3 }}>
                    <SelectComponent
                      value={(formData.driver && formData.driver.id) || 'Select a driver'}
                      defaultTitle="Select a driver"
                      data={drivers}
                      onSelect={(e) => handleSelectDriver(e)}
                      key="formDriver"
                      required
                      isInvalid={
                        showValidation &&
                        (!formData.isDriverValid || !formData.isDriverDocumentValid)
                      }
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Button variant="inverse" onClick={() => handleClickAddDriver()}>
                      Add driver
                    </Button>
                  </div>
                </div>
                {job.areDriverDocumentsRequired && (
                  <div
                    className="mb-3"
                    style={{
                      color: formData.isDriverDocumentValid ? 'black' : 'red',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>Proof of driver insurance and license of driver are required.</span>
                    <span>
                      Add the documents to the driver on the Company details page if they are not
                      added yet.
                    </span>
                  </div>
                )}
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 80,
                }}
              >
                <Button variant="inverse" onClick={() => navigate('/')}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Accept job
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      {isAddDriver && (
        <DriverDetails
          onSubmit={handleSubmitDriver}
          onClose={() => setIsAddDriver(false)}
          show={isAddDriver}
          title="Add driver"
        />
      )}
    </div>
  );
}
