import decapitalizeArray from '../../util/decapitalizeArray';

export default async function getDrivers(token) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/drivers`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const jsonData = await response.json();
      if (response.ok) {
        const drivers = await await Promise.all(
          jsonData.map(async (driver) => {
            const documents = await decapitalizeArray(driver.Documents);
            return {
              name: driver.Name,
              lastName: driver.LastName,
              id: driver.DriverId,
              documents,
            };
          }),
        );
        return drivers;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
