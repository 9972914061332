import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import getCompany from '../../api/companies/getCompany';
import patchJob from '../../api/jobs/patchJobPickedUp';
import Popup from '../../components/confirmationModal';
import Header from '../../components/header';
import jobSummary from '../../components/jobSummary';
import { useJobStore } from '../../setup/stores/jobStore';
import { isValid } from './util/isValid';

export default function PickupJob() {
  const [job] = useJobStore((state) => [state.job]);
  const [formData, setFormData] = useState({
    pickupDate: '',
    isAccepted: false,
    isPickupDateValid: true,
  });
  const [showValidation, setShowValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  if (job.id !== id) {
    navigate('/');
  }

  const checkCompany = async () => {
    try {
      const token = await getAccessTokenSilently();
      const companyObject = await getCompany(token);
      if (!companyObject.contactPerson) {
        navigate('/company');
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    checkCompany();
  }, []);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.id]: value,
    });
  };

  const handleSubmit = async () => {
    if (!isValid(formData, job)) {
      setShowValidation(true);
      window.scrollTo(0, 0);
    } else {
      setShowValidation(false);
      const token = await getAccessTokenSilently();
      const response = await patchJob(token, id, formData.pickupDate);
      if (response) {
        navigate('/');
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalConfirm = () => {
    setShowModal(false);
    handleSubmit();
  };

  return (
    <div>
      <Header first="Pickup" second=" Job" />
      <Container>
        <Form>
          <Popup
            show={showModal}
            title="Confirm"
            body="Confirm that the CMR is accepted"
            secondaryCaption="Cancel"
            primaryCaption="Ok"
            onClose={handleModalClose}
            onContinue={handleModalConfirm}
          />
          <h2 style={{ marginTop: 50, marginBottom: 25 }}>Pickup</h2>
          <div className="mb-3">{jobSummary(job)}</div>
          <Row>
            <Col>
              <Form.Group controlId="pickupDate">
                <Form.Label>Pickup Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter pickup date"
                  value={formData.pickupDate}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !formData.isPickupDateValid}
                  max={new Date().toISOString().split('T')[0]}
                />
              </Form.Group>
              <Form.Group controlId="isAccepted" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="CMR Accepted"
                  checked={formData.isAccepted}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !formData.isAccepted}
                />
              </Form.Group>
              <div style={{ display: 'flex', gap: 15 }} className="mt-5">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Picked up car(s)
                </Button>
              </div>
            </Col>
            <Col />
          </Row>
        </Form>
      </Container>
    </div>
  );
}
