import React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as PenIcon } from '../../../assets/pen.svg';
import { ReactComponent as FileLogo } from '../../../assets/file.svg';

export default function Drivers(props) {
  const { drivers, onClickAdd, onClickDetails, onDelete } = props;

  return (
    <div>
      <h4>Drivers</h4>
      <Button variant="secondary" className="ml-2 mb-3" onClick={() => onClickAdd()}>
        Add driver
      </Button>
      {drivers && (
        <Table>
          <thead>
            <tr key={1}>
              <th> </th>
              <th>Name</th>
              <th>Documents</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver) => (
              <tr key={driver.id}>
                <td style={{ verticalAlign: 'middle' }}>
                  <Button variant="link" className="ml-2" onClick={() => onClickDetails(driver)}>
                    <PenIcon style={{ color: 'black' }} />
                  </Button>
                </td>
                <td style={{ verticalAlign: 'middle' }}>{`${driver.name} ${driver.lastName}`}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {driver.documents.map((document) => {
                      return (
                        <div
                          style={{ display: 'flex', alignItems: 'start', gap: 5 }}
                          key={document.documentId}
                        >
                          <FileLogo />
                          <span>{document.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Button variant="link" className="ml-2" onClick={() => onDelete(driver)}>
                    <DeleteIcon style={{ color: 'red' }} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
