export default async function getUserInformation(token) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/user-information`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const user = await response.json();

      if (response.ok) {
        return { blockedUntil: user.BlockedUntil, blockedReason: user.BlockedReason };
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
