import { countries } from '../../util/countries';

export default async function postCompany(token, company) {
  let data;

  if (process.env.REACT_APP_IS_SPRING_BACKEND_ACTIVE === 'true') {
    const countryCode = countries.find((country) => country.name === company.country)?.code;

    data = {
      name: company.name,
      street: company.street,
      houseNumber: company.houseNumber,
      zipCode: company.zipcode,
      city: company.city,
      country: countryCode,
      vatNumber: company.vatNumber,
      email: company.email,
      language: company.language.toUpperCase(),
      contactPerson: company.contactPerson,
    };
  } else {
    data = {
      Name: company.name,
      Street: company.street,
      HouseNumber: company.houseNumber,
      Zipcode: company.zipcode,
      City: company.city,
      Country: company.country,
      VatNumber: company.vatNumber,
      Email: company.email,
      Language: company.language,
      ContactPerson: company.contactPerson,
    };
  }

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/companies`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      return response.ok;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
