import capitalizeArray from '../../util/capitalizeArray';
import decapitalizeArray from '../../util/decapitalizeArray';

export default async function postCalculation(token, calculation) {
  const verhiclesForMapping = await capitalizeArray(calculation.vehicles);
  const data = {
    CityFrom: calculation.cityFrom,
    CountryFrom: calculation.countryFrom,
    CountryFromCode: calculation.countryFromCode,
    CityTo: calculation.cityTo,
    CountryTo: calculation.countryTo,
    CountryToCode: calculation.countryToCode,
    LatitudeFrom: calculation.latitudeFrom,
    LongitudeFrom: calculation.longitudeFrom,
    LatitudeTo: calculation.latitudeTo,
    LongitudeTo: calculation.longitudeTo,
    Vehicles: verhiclesForMapping,
  };

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/calculation`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      const jsonData = await response.json();
      const vehicles = jsonData.Vehicles
        ? await decapitalizeArray(jsonData.Vehicles)
        : jsonData.vehicles;
      const calculationResult = {
        price: jsonData.Price || jsonData.price,
        discount: jsonData.DiscountAmount || jsonData.discountAmount,
        distance: jsonData.Distance || jsonData.distanceInKm,
        jobUuid: jsonData.JobUuid || jsonData.jobUuid,
        vehicles: vehicles.length > 0 ? vehicles : jsonData.vehicles,
      };

      return calculationResult;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
