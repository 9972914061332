import React from 'react';
import useUserStore from '../setup/stores/userStore';

const commonStyles = {
  backgroundColor: '#de7125',
  padding: '9px',
  textAlign: 'center',
  color: 'white',
  margin: '0',
};

function Banner() {
  const [blockedUntil, blockedReason, isBlocked] = useUserStore((state) => [
    state.blockedUntil,
    state.blockedReason,
    state.isBlocked,
  ]);

  if (!isBlocked) {
    return null;
  }

  return (
    <div>
      <h2 style={commonStyles}>
        Access restricted, you can resume normal activities after:
        <span>&nbsp;</span>
        {new Date(blockedUntil).toLocaleDateString('nl-NL', { timeZone: 'UTC' })}
      </h2>
      {blockedReason && (
        <h2 style={commonStyles}>
          Reason:
          <span>&nbsp;</span>
          {blockedReason}
        </h2>
      )}
    </div>
  );
}

export default Banner;
