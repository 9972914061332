import React from 'react';
import Container from 'react-bootstrap/Container';

function Header(props) {
  const { first, component, button } = props;
  return (
    <div className="header" style={{ paddingLeft: 0, minHeight: 150, maxHeight: 250 }}>
      <Container style={{ minHeight: 10, maxHeight: 250 }}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 150,
            maxHeight: 250,
            marginTop: 20,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h1 className="first-word-color">{first}</h1>
            {button}
          </div>
          <div>{component}</div>
        </div>
      </Container>
    </div>
  );
}

export default Header;
