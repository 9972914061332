export default async function getJobs(token) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/count/jobs        
        `,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.ok) {
        const jsonData = await response.json();
        const jobResponse = {
          totalRowsToPlan: jsonData.TotalRowsToPlan,
          totalRowsPlanned: jsonData.TotalRowsPlanned,
          totalRowsLoaded: jsonData.TotalRowsLoaded,
          totalRowsUnloaded: jsonData.TotalRowsUnloaded,
          totalRowsClosed: jsonData.TotalRowsClosed,
          totalRowsDraft: jsonData.TotalRowsDraft,
        };
        return jobResponse;
      }
    } catch (e) {
      console.error(e);
      return {};
    }
  }
  return null;
}
