import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import LogoutButton from './logoutButton';

export default function NavbarComponent(props) {
  const { isAuthenticated, username } = props;
  const navigate = useNavigate();

  const handleClickCompany = () => {
    navigate('/company');
  };
  const handleClickHome = () => {
    navigate('/');
  };

  return (
    <Navbar
      bg="white"
      variant="light"
      expand="lg"
      sticky="top"
      style={{ paddingLeft: 15, paddingRight: 15 }}
    >
      <Container>
        <Navbar.Brand onClick={() => handleClickHome()} style={{ paddingRight: 15 }}>
          <Logo style={{ height: 70, width: 165 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={{ paddingLeft: 15 }}>
            <Nav.Link onClick={() => handleClickHome()}>Home</Nav.Link>
          </Nav>
          <Nav className="mr-auto" style={{ paddingLeft: 15 }}>
            <Nav.Link onClick={() => handleClickCompany()}>Company details</Nav.Link>
          </Nav>
          <Nav className="ms-auto" style={{ paddingRight: 15, paddingLeft: 15 }}>
            {isAuthenticated && (
              <NavDropdown
                title={
                  <span>
                    <FaUser />
                    {` ${username}`}
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <LogoutButton />
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
