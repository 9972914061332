import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Fade from 'react-bootstrap/Fade';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import getJob from '../../api/jobs/getJob';
import patchJob from '../../api/jobs/patchJobComment';
import patchJobConfirm from '../../api/jobs/patchJobDeliveryConfirmed';
import patchJobDate from '../../api/jobs/patchJobDate';
import patchJobDriver from '../../api/jobs/patchJobDriver';
import { ReactComponent as LeftArrow } from '../../assets/arrow-left.svg';
import Popup from '../../components/confirmationModal';
import Header from '../../components/header';
import { useJobStore } from '../../setup/stores/jobStore';
import useUserStore from '../../setup/stores/userStore';
import Communication from './components/communication';
import Files from './components/files';
import OrderDetails from './components/orderDetails';
import TransportDetails from './components/transportDetails';
import TransporterDetails from './components/transporterDetails';
import VehicleDetails from './components/vehicleDetails';

export default function JobDetails() {
  const [isTransporter, isClient, isBlocked] = useUserStore((state) => [
    state.isTransporter,
    state.isClient,
    state.isBlocked,
  ]);
  const [showModal, setShowModal] = useState(false);
  const [updateJob] = useJobStore((state) => [state.updateJob]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDriverChanging, setIsDriverChanging] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const { id } = useParams();
  const initialState = {
    id,
    status: null,
    price: null,
    vehicles: [],
    readyFrom: null,
    additionalInfo: null,
    commentTransporter: null,
    commentClient: null,
    companyFrom: {
      name: null,
      street: null,
      houseNumber: null,
      zipcode: null,
      city: null,
      country: null,
      email: null,
      language: null,
      contactPerson: null,
      telephone: null,
      openingHours: [],
    },
    companyTo: {
      name: null,
      street: null,
      houseNumber: null,
      zipcode: null,
      city: null,
      country: null,
      email: null,
      language: null,
      contactPerson: null,
      telephone: null,
      openingHours: [],
    },
    transporter: {
      name: null,
      id: null,
      companyName: null,
      companyId: null,
    },
    driver: {
      name: null,
      id: null,
      lastName: null,
      licensePlate: null,
    },
    documents: [],
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getJobData = async () => {
      const token = await getAccessTokenSilently();
      const job = await getJob(token, id);
      setFormData(job);
      setIsLoading(false);
    };

    getJobData();
  }, []);

  useEffect(() => {
    updateJob(formData);
  }, [formData]);

  const handleSave = async (action) => {
    const token = await getAccessTokenSilently();
    patchJobDate(token, id, formData.pickupDate, formData.deliveryDate, action);
  };

  const handleDriverChangeSave = async (driverId, licensePlate) => {
    setIsDriverChanging(true);
    const token = await getAccessTokenSilently();
    await patchJobDriver(token, id, driverId, licensePlate);
    const job = await getJob(token, id);
    setFormData(job);
    setIsDriverChanging(false);
  };

  const handleChange = async (e) => {
    if (e) {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.id]: e.target.value,
      }));
    } else {
      const token = await getAccessTokenSilently();
      const job = await getJob(token, id);
      setFormData(job);
    }
  };

  const handleBlur = async (action) => {
    const token = await getAccessTokenSilently();
    patchJob(token, formData.id, formData.commentTransporter, formData.commentClient, action);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalConfirm = async () => {
    const token = await getAccessTokenSilently();
    setShowModal(false);
    patchJobConfirm(token, formData.id);
    navigate('/');
  };

  return (
    <>
      <Header first="Order" second=" Details" />
      <Popup
        show={showModal}
        title="Confirm"
        body="Confirm that the cars are delivered and CMR has been chcked"
        secondaryCaption="Cancel"
        primaryCaption="Ok"
        onClose={handleModalClose}
        onContinue={handleModalConfirm}
      />
      <div style={{ marginBottom: 50 }}>
        <Fade in={!isLoading}>
          <Container>
            <Form aria-disabled>
              <div
                style={{
                  marginTop: 32,
                  marginBottom: 32,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button variant="inverse" onClick={() => navigate('/')}>
                  <LeftArrow className="button-icon-left" />
                  Back
                </Button>
                {isTransporter && !isBlocked && (
                  <>
                    {formData.status === 'ToPlan' && (
                      <Button variant="primary" onClick={() => navigate(`/jobs/${id}/accept`)}>
                        Take order
                      </Button>
                    )}
                    {formData.status === 'Planned' && (
                      <Button variant="primary" onClick={() => navigate(`/jobs/${id}/pickup`)}>
                        Picked up car(s)
                      </Button>
                    )}
                    {formData.status === 'Loaded' && (
                      <Button variant="primary" onClick={() => navigate(`/jobs/${id}/deliver`)}>
                        Delivered car(s)
                      </Button>
                    )}
                  </>
                )}
              </div>
              <Row>
                <OrderDetails formData={formData} isTransporter={isTransporter} />
              </Row>
              <Row>
                <Col xs={12} lg={8} style={{ marginRight: 8 }}>
                  <Row style={{ marginTop: 40 }}>
                    <TransportDetails
                      onSave={handleSave}
                      isEditable={isTransporter}
                      isReadOnly={formData.status === 'Closed'}
                      formData={formData}
                      isClient={isClient}
                    />
                  </Row>
                  <Row style={{ marginTop: 40 }}>
                    <VehicleDetails formData={formData} />
                  </Row>
                  {((isTransporter && formData.status !== 'ToPlan') || isClient) && (
                    <Row style={{ marginTop: 40 }}>
                      <Files
                        title="Documents"
                        onChange={handleChange}
                        files={formData.documents}
                        isReadOnly={formData.status === 'Closed' && !isTransporter}
                        documentType={isTransporter ? 'Cmr' : 'Client'}
                        jobId={formData.id}
                        isJobOpen={formData.status !== 'Closed'}
                        isTransporter={isTransporter}
                        isChangeActive={isDriverChanging}
                      />
                    </Row>
                  )}
                </Col>
                {formData.status !== 'ToPlan' && (
                  <Col>
                    <Row style={{ marginTop: 40 }}>
                      <TransporterDetails
                        formData={formData}
                        isEditable={isTransporter && formData.status !== 'Closed'}
                        onSave={handleDriverChangeSave}
                        isChangeActive={isDriverChanging}
                      />
                    </Row>
                    {formData.status !== 'ToPlan' && (
                      <Row style={{ marginTop: 40 }}>
                        <Communication
                          title="Transporter notes"
                          value={formData.commentTransporter}
                          controlId="commentTransporter"
                          onChange={handleChange}
                          isReadOnly={!isTransporter || formData.status === 'Closed'}
                          onBlur={handleBlur}
                        />
                      </Row>
                    )}
                    {formData.status !== 'ToPlan' && (
                      <Row style={{ marginTop: 40 }}>
                        <Communication
                          title="Client notes"
                          value={formData.commentClient}
                          controlId="commentClient"
                          onChange={handleChange}
                          isReadOnly={!isClient}
                          onBlur={handleBlur}
                        />
                      </Row>
                    )}
                  </Col>
                )}
              </Row>
            </Form>
          </Container>
        </Fade>
      </div>
    </>
  );
}
