export default async function decapitalizeArray(array) {
  if (array) {
    const decapitalizedKeysArray = array.map((obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const decapitalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[decapitalizedKey] = value;
        return acc;
      }, {});
    });

    return decapitalizedKeysArray;
  }
  return [];
}
