import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectComponent(props) {
  const { data, defaultTitle, onSelect, value, isInvalid } = props;

  const getDropdownItems = () => {
    if (data) {
      return data.map((item) => {
        return (
          <option value={item.code ?? item.id} key={item.code ?? item.id}>
            {item.name}
          </option>
        );
      });
    }
    return <div />;
  };

  const handleSelect = (e) => {
    let object = { name: '', code: null };
    if (e !== defaultTitle) {
      object = data.find((x) => x.code === e || x.id === e);
    }
    onSelect(object);
  };

  return (
    <Form.Select onChange={(e) => handleSelect(e.target.value)} value={value} isInvalid={isInvalid}>
      <option value={null} key={1}>
        {defaultTitle}
      </option>
      {getDropdownItems()}
    </Form.Select>
  );
}
