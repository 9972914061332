import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

export default function FileUpload(props) {
  const { selectedFiles, onSelect, label, multiple } = props;
  const [validationErrors, setValidationErrors] = useState([]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 5) {
      onSelect([]);
      setValidationErrors([
        {
          id: Math.random(),
          message: 'Only a maximum of 5 files can be uploaded.',
        },
      ]);
      return;
    }

    const errors = [];
    const validatedFiles = files.filter((file) => {
      if (file.size <= 14 * 1024 * 1024) {
        return true;
      }

      errors.push({
        id: file.name + Math.random(),
        message: `File '${file.name}' exceeds the max file size (14MB).`,
      });

      return false;
    });
    setValidationErrors(errors);
    onSelect(validatedFiles);
  };

  return (
    <div>
      <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="file"
          accept=".jpg, .jpeg, .png, .pdf"
          onChange={handleFileSelect}
          multiple={multiple}
        />

        {validationErrors.map((error) => {
          return (
            <div style={{ fontSize: 12, color: 'red', marginTop: 5 }} key={error.id}>
              {error.message}
            </div>
          );
        })}
      </Form.Group>

      {selectedFiles.length > 0 && multiple && (
        <div>
          <h6>File(s) to upload:</h6>
          <ListGroup>
            {selectedFiles.map((filteredFile) => (
              <ListGroup.Item key={filteredFile.name}>{filteredFile.name}</ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
}
