import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as FlagImage } from '../assets/flag.svg';

const style = {
  listViewDetailsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
    minWidth: 250,
    marginBottom: 5,
  },
  text: {
    label: { display: 'flex', width: 'fit-content', marginRight: 10 },
    header: { fontWeight: 600, display: 'flex', alignItems: 'center' },
    big: { fontSize: 18, fontWeight: 600 },
    extraBig: { fontSize: 20, fontWeight: 600 },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

function jobSummary(props) {
  const { companyFrom, companyTo, price, readyFrom, pickupDate, status } = props;
  const classNameForDates = status === 'Loaded' ? 'mb-2' : 'mb-4';
  return (
    <div>
      <div style={style.labelContainer}>
        <div className={`secondary-color ${classNameForDates}`} style={style.text.label}>
          Ready from:
        </div>
        <div>{new Date(readyFrom).toLocaleDateString('nl-NL', { timeZone: 'UTC' })}</div>
      </div>
      {status === 'Loaded' && (
        <div style={style.labelContainer}>
          <div className="secondary-color mb-4" style={style.text.label}>
            Pickup date:
          </div>
          <div>{new Date(pickupDate).toLocaleDateString('nl-NL', { timeZone: 'UTC' })}</div>
        </div>
      )}
      <Row>
        <Col>
          <div style={style.listViewDetailsContainer}>
            <div style={style.text.header}>
              <FlagImage className="text-icon-left" />
              Pickup
            </div>
            <div style={style.labelContainer}>
              <div className="secondary-color" style={style.text.label}>
                Location:
              </div>
              <div>{`${companyFrom.city}, ${companyFrom.country}`}</div>
            </div>
          </div>
        </Col>
        <Col>
          <div style={style.listViewDetailsContainer}>
            <div style={style.text.header}>
              <FlagImage className="text-icon-left" />
              Delivery
            </div>
            <div style={style.labelContainer}>
              <div className="secondary-color" style={style.text.label}>
                Location:
              </div>
              <div>{`${companyTo.city}, ${companyTo.country}`}</div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-4">Our price</div>
      <div className="primary-color" style={style.text.extraBig}>
        {`€ ${Math.round(price)},-`}
      </div>
    </div>
  );
}

export default jobSummary;
