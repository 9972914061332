import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import postDriver from '../../api/drivers/postDriver';
import postDriverDocument from '../../api/drivers/postDriverDocument';
import Files from '../../components/driverFiles';
import { isValid } from './util/isDriverValid';

export default function DriverDetails(props) {
  const { onClose, onSubmit, show, title, driver } = props;
  const [showValidation, setShowValidation] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [files, setFiles] = useState([]);

  const handleSubmit = async () => {
    let driverIdToUse = driver?.id;
    if (!isValid(name, lastName)) {
      setShowValidation(true);
    } else {
      setIsLoading(true);
      setShowValidation(false);

      const newDriver = {
        name,
        lastName,
      };
      const token = await getAccessTokenSilently();
      if (!driverIdToUse) {
        driverIdToUse = await postDriver(token, newDriver);
      }

      await Promise.all(
        files.map((file) => {
          if (!file.documentId) {
            return postDriverDocument(token, file, driverIdToUse);
          }
          return file.fileId;
        }),
      );

      setIsLoading(false);
      onSubmit();
    }
  };

  const handleFileChange = (newFiles, isDelete) => {
    if (isDelete) {
      setFiles(newFiles);
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(() => {
    if (driver) {
      setName(driver.name);
      setLastName(driver.lastName);
      setFiles(driver.documents);
    }
  }, []);

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <h2>{title}</h2>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={8} sm={12}>
            <Form.Group controlId="name">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                isInvalid={showValidation && name === ''}
                disabled={driver}
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                isInvalid={showValidation && lastName === ''}
                disabled={driver}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Files
            title="Documents"
            files={files}
            onChange={handleFileChange}
            isDownloadPossible={false}
          />
        </Row>
      </Modal.Body>
      {!isLoading && (
        <Modal.Footer>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="inverse" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleSubmit()}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
