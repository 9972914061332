// eslint-disable-next-line import/prefer-default-export
export const countries = [
  { name: 'Belgium', code: 'BE' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Germany', code: 'DE' },
  { name: 'Estonia', code: 'EE' },
  { name: 'France', code: 'FR' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Italy', code: 'IT' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Austria', code: 'AT' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Romania', code: 'RO' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  // { name: 'United Kingdom', code: 'GB' },
];
