import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function CmrInfo(props) {
  const { onCancel, onConfirm, show } = props;
  return (
    <Modal show={show} onHide={() => onCancel()}>
      <Modal.Header closeButton>
        <h3>Please accept our Signed CMR procedure</h3>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            Better to prevent than to cure. Not following the correct procedure could have
            significant negative consequences. Without any proof of damage or lack of listed extras,
            you will be held liable. So, be smart and follow the procedures, this way you will
            always be covered.
          </div>
          <div style={{ fontWeight: 600 }} className="mt-2">
            We highlighted the most important conditions for you below.
          </div>
          <div>
            <h3 className="mt-2">Identify</h3>
            <div>
              The chassis number matches the number on the order. If it doesn&apos;t? Call
              Transporting Highway.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Check</h3>
            <div>
              Indicate the status of the car on the CMR. Indicate damages, dents and scratches and
              always take photos of the car.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Extras</h3>
            <div>
              All listed extras such as keys, charging cables and documents are present, if not call
              Transporting Highway.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Review</h3>
            <div>
              CMR procedure has been completed fully and correctly. Please take a moment to review
              it. This will save time and money if a problem arises.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Billing</h3>
            <div>
              Carriers will be paid by Transporting Highway within 14 days of completion of the
              assignment. The invoice must be sent to Transporting Highway.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Insurance</h3>
            <div>
              Transporters have insurance for consequential damage to goods, liability insurance and
              corporate liability insurance. Of these, they should carry the appropriate papers.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Accepting an order</h3>
            <div>
              When an order is accepted, it must be ensured that a driver is available within 24
              hours. Is this not the case? Then the order will go to another carrier.
            </div>
          </div>
          <div>
            <h3 className="mt-2">Communication</h3>
            <div>
              In case of unforeseen circumstances arising during the journey, the carrier should
              inform Transporting Highway immediately. In addition, the transporter should provide
              status updates on the assignment if requested by Transporting Highway.
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="inverse" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onConfirm()}>
            Confirm CMR
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
