import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import postJobFile from '../api/job-documents/postJobDocument';
import FileUpload from './fileUpload';

export default function FileUploadModal(props) {
  const { onClose, onFileChange, show, objectId, documentType, type } = props;
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleSelectFiles = (newFiles) => {
    const updatedList = [];
    newFiles.forEach((file) => {
      file.documentType = documentType; // eslint-disable-line no-param-reassign
      updatedList.push(file);
    });
    setFiles(updatedList);
  };

  const postFiles = async () => {
    const token = await getAccessTokenSilently();
    const promises = files.map((file) => {
      if (type === 'job') {
        return postJobFile(token, objectId, file);
      }
      return 'done';
    });
    const results = await Promise.all(promises);
    return results;
  };

  const handleClickSave = async () => {
    setIsLoading(true);
    await postFiles();
    onFileChange(files);
    setIsLoading(false);
    setFiles([]);
  };

  const handleClose = () => {
    if (!isLoading) {
      setFiles([]);
      onClose();
    }
  };

  return (
    <Modal className={type === 'driver' ? 'large-modal' : ''} show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Upload file(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUpload
          selectedFiles={files}
          onSelect={handleSelectFiles}
          label="Files (image or Pdf):"
          multiple
        />
        {isLoading && <Spinner animation="border" className="mt-3" />}
      </Modal.Body>
      {!isLoading && (
        <Modal.Footer>
          <Button variant="default" onClick={() => handleClose()}>
            Cancel
          </Button>
          {files.length > 0 && (
            <Button variant="primary" onClick={() => handleClickSave()}>
              Save
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}
