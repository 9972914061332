import React from 'react';
import Vehicle from './vehicle';
import Vehicles from './vehicles';

function VehicleDetails(props) {
  const { vehicle, vehicles, onChangeVehicle, onClickAddVehicle, onClickDeleteVehicle } = props;
  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Add vehicles</h2>
      <span>Please add vehicles that need to be transported.</span>
      <div style={{ display: 'flex', width: '100%', marginTop: 50 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 50, width: '100%' }}>
          <Vehicle
            vehicle={vehicle}
            onChangeVehicle={onChangeVehicle}
            onClickAddVehicle={onClickAddVehicle}
          />

          {vehicles.length > 0 && (
            <Vehicles vehicles={vehicles} onClickDelete={onClickDeleteVehicle} />
          )}
        </div>
      </div>
    </div>
  );
}

export default VehicleDetails;
