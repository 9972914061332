import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import getDrivers from '../../../api/drivers/getDrivers';
import SelectComponent from '../../../components/select';
import DriverDetails from '../../driver-details/driverDetails';
import { isValid } from '../util/isDriverChangeValid';

export default function ChangeDriver(props) {
  const { show, onCancel, onConfirm, formData } = props;
  const [isAddDriver, setIsAddDriver] = useState(false);
  const [driverInfo, setDriverInfo] = useState({
    id: '',
    licensePlate: '',
  });
  const [drivers, setDrivers] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchDrivers = async () => {
    const token = await getAccessTokenSilently();
    const driverObjects = await getDrivers(token);
    const driversForSelect = driverObjects.map((driver) => {
      return { name: `${driver.name} ${driver.lastName}`, id: driver.id };
    });
    setDrivers(driversForSelect);
  };

  useEffect(() => {
    const updatedDriverInfo = { ...formData.driver };
    setDriverInfo(updatedDriverInfo);
    fetchDrivers();
  }, []);

  const handleClickAddDriver = () => {
    setIsAddDriver(true);
  };

  const handleSubmitDriver = async () => {
    setIsAddDriver(false);
    fetchDrivers();
  };

  const handleSelectDriver = (driver) => {
    const updatedFormData = { ...driverInfo };
    updatedFormData.id = driver.id;
    setDriverInfo(updatedFormData);
  };

  const handleChange = (e) => {
    const updatedFormData = { ...driverInfo };
    updatedFormData[e.target.id] = e.target.value;
    setDriverInfo(updatedFormData);
  };

  const onSave = () => {
    if (isValid(driverInfo.id)) {
      onConfirm(driverInfo.id, driverInfo.licensePlate);
    } else {
      setShowValidation(true);
    }
  };

  return (
    <Modal show={show} onHide={() => onCancel()}>
      <Modal.Header closeButton>
        <h2>Change driver</h2>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group controlId="licensePlate" className="mt-2">
            <Form.Label>License plate transporting truck</Form.Label>
            <Form.Control
              type="text"
              value={driverInfo.licensePlate ?? ''}
              onChange={handleChange}
              maxLength={20}
            />
          </Form.Group>
          <Form.Group controlId="formDriver" className="mt-2">
            <Form.Label>Driver*</Form.Label>
            <div style={{ display: 'flex', alignItems: 'center', gap: 50 }}>
              <div style={{ flex: 3 }}>
                <SelectComponent
                  value={driverInfo.id}
                  defaultTitle="Select a driver"
                  data={drivers}
                  onSelect={(e) => handleSelectDriver(e)}
                  key="formDriver"
                  required
                  isInvalid={showValidation && !driverInfo.id}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Button variant="inverse" onClick={() => handleClickAddDriver()}>
                  Add driver
                </Button>
              </div>
            </div>
          </Form.Group>
          {isAddDriver && (
            <DriverDetails
              onSubmit={handleSubmitDriver}
              onClose={() => setIsAddDriver(false)}
              show={isAddDriver}
              title="Add driver"
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="inverse" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onSave()}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
