import React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';

export default function Vehicles(props) {
  const { vehicles, onClickDelete } = props;

  const getTypeCaption = (vehicleType) => {
    if (vehicleType === 'Standard') {
      return 'Standard';
    }
    if (vehicleType === 'Suv') {
      return 'Suv';
    }
    if (vehicleType === 'VanSmall') {
      return 'Bus (max. L1H1)';
    }
    return 'Bus (max. L3H3)';
  };

  const getVehicles = () => {
    if (vehicles) {
      return vehicles.map((vehicle) => {
        return (
          <tr key={vehicle.id}>
            <td className="align-middle">{getTypeCaption(vehicle.vehicleType)}</td>
            <td className="align-middle">{vehicle.electric ? 'Yes' : 'No'}</td>
            <td className="align-middle">{vehicle.nonRunner ? 'Yes' : 'No'}</td>
            <td className="align-middle">{vehicle.amount}</td>
            <td className="align-middle">
              <Button variant="link" className="ps-0" onClick={() => onClickDelete(vehicle)}>
                <DeleteIcon style={{ color: 'red', marginBottom: 5 }} />
              </Button>
            </td>
          </tr>
        );
      });
    }
    return <div />;
  };

  return (
    <div className="light-background" style={{ padding: 10 }}>
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Electric</th>
            <th>Non runner</th>
            <th>Amount</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>{getVehicles()}</tbody>
      </Table>
    </div>
  );
}
