import capitalizeArray from '../../util/capitalizeArray';

export default async function updateJob(token, job, action) {
  const verhiclesForMapping = await capitalizeArray(job.vehicles);
  const openingTimesFromForMapping = await capitalizeArray(job.companyFrom.openingHours);
  const openingTimesToForMapping = await capitalizeArray(job.companyTo.openingHours);

  const data = {
    JobUuid: job.id,
    Vehicles: verhiclesForMapping,
    AdditionalInformation: job.additionalInfo,
    ReadyFrom: job.readyFrom,
    CompanyFrom: {
      Name: job.companyFrom.name,
      Street: job.companyFrom.street,
      HouseNumber: job.companyFrom.houseNumber,
      Zipcode: job.companyFrom.zipcode,
      City: job.companyFrom.city,
      Country: job.companyFrom.country,
      Email: job.companyFrom.email,
      Language: job.companyFrom.language,
      ContactPerson: job.companyFrom.contactPerson,
      Telephone: job.companyFrom.telephone,
      OpeningTimes: openingTimesFromForMapping,
    },
    CompanyTo: {
      Name: job.companyTo.name,
      Street: job.companyTo.street,
      HouseNumber: job.companyTo.houseNumber,
      Zipcode: job.companyTo.zipcode,
      City: job.companyTo.city,
      Country: job.companyTo.country,
      Email: job.companyTo.email,
      Language: job.companyTo.language,
      ContactPerson: job.companyTo.contactPerson,
      Telephone: job.companyTo.telephone,
      OpeningTimes: openingTimesToForMapping,
    },
  };

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/jobs/${action}`,
        {
          method: 'PUT',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      return response.ok;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
