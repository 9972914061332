import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function JobTabs(props) {
  const { activeTab, onTabChange, jobCount } = props;

  return (
    <Tabs activeKey={activeTab} onSelect={(tab) => onTabChange(tab)} id="job-tab" justify>
      <Tab eventKey="Draft" title={`Draft (${jobCount.totalRowsDraft ?? 0})`} />
      <Tab eventKey="ToPlan" title={`To plan (${jobCount.totalRowsToPlan ?? 0})`} />
      <Tab eventKey="Planned" title={`Planned (${jobCount.totalRowsPlanned ?? 0})`} />
      <Tab eventKey="Loaded" title={`Loaded (${jobCount.totalRowsLoaded ?? 0})`} />
      <Tab eventKey="Unloaded" title={`Unloaded (${jobCount.totalRowsUnloaded ?? 0})`} />
      <Tab eventKey="Closed" title={`Closed (${jobCount.totalRowsClosed ?? 0})`} />
    </Tabs>
  );
}

export default JobTabs;
