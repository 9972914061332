import React from 'react';
import Form from 'react-bootstrap/Form';

export default function Communication(props) {
  const { title, controlId, value, onChange, onBlur, isReadOnly } = props;

  return (
    <div>
      <h3>{title}</h3>
      <Form.Group controlId={controlId}>
        <Form.Label>Comment</Form.Label>
        {!isReadOnly && (
          <Form.Control
            as="textarea"
            value={value ?? ''}
            onChange={onChange}
            onBlur={() => onBlur(controlId)}
            maxLength="4000"
            rows="5"
          />
        )}
        {isReadOnly && <Form.Control rows="5" as="textarea" disabled value={value ?? ''} />}
      </Form.Group>
    </div>
  );
}
