import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import getJobsCount from '../../api/jobs/getJobsCount';
import Jobs from '../../components/jobs';
import Header from './components/header';
import JobTabs from './components/jobTabs';

export default function HomeTransporter() {
  const [activeTab, setActiveTab] = useState('ToPlan');
  const [jobCount, setJobCount] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const fetchJobCount = async () => {
    const token = await getAccessTokenSilently();
    const response = await getJobsCount(token);
    if (response) {
      setJobCount(response);
    }
  };

  const handleJobChange = () => {
    fetchJobCount();
  };

  useEffect(() => {
    fetchJobCount();
  }, []);

  return (
    <div>
      <Header
        first="Orders"
        component={
          <JobTabs activeTab={activeTab} onTabChange={handleTabChange} jobCount={jobCount} />
        }
      />

      <Container>
        {activeTab === 'ToPlan' && (
          <Jobs
            status="ToPlan"
            title="To plan"
            jobCount={jobCount.totalRowsToPlan}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Planned' && (
          <Jobs
            status="Planned"
            title="Planned"
            jobCount={jobCount.totalRowsPlanned}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Loaded' && (
          <Jobs
            status="Loaded"
            title="Loaded"
            jobCount={jobCount.totalRowsLoaded}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Unloaded' && (
          <Jobs
            status="Unloaded"
            title="Unloaded"
            jobCount={jobCount.totalRowsUnloaded}
            onJobChange={handleJobChange}
          />
        )}
        {activeTab === 'Closed' && (
          <Jobs
            status="Closed"
            title="Closed"
            jobCount={jobCount.TotalRowsClosed}
            onJobChange={handleJobChange}
          />
        )}
      </Container>
    </div>
  );
}
