import React from 'react';
import Container from 'react-bootstrap/Container';

function Header(props) {
  const { first, second } = props;
  return (
    <div className="header">
      <Container>
        <h1 className="first-word-color">
          {first}
          <span>{` ${second}`}</span>
        </h1>
      </Container>
    </div>
  );
}

export default Header;
