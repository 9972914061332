import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useJobStore } from '../../setup/stores/jobStore';
import useUserStore from '../../setup/stores/userStore';

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  const [reset] = useUserStore((state) => [state.reset]);
  const [resetJob] = useJobStore((state) => [state.resetJob]);

  useEffect(() => {
    reset();
    resetJob();
    loginWithRedirect({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
        redirect_uri: window.location.origin,
      },
    });
  }, []);

  return <div />;
}
