import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { FaRegEdit } from 'react-icons/fa';
import ChangeDriver from './changeDriver';

export default function TransporterDetails(props) {
  const { formData, isEditable, onSave, isChangeActive } = props;
  const [showModal, setShowModal] = useState(false);

  const handleSave = (driverId, licensePlate) => {
    onSave(driverId, licensePlate);
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <ChangeDriver
          show={showModal}
          onCancel={() => setShowModal(false)}
          formData={formData}
          onConfirm={handleSave}
        />
      )}
      <h3 className="mb-4">Transporter details</h3>
      <div className="light-background p-3">
        <Table className="table-borderless darker-color mb-0">
          <thead>
            <tr>
              <th className="pb-0">Company</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {formData.transporter.companyName ? (
                <td>{formData.transporter.companyName}</td>
              ) : (
                <td className="fst-italic secondary-color">Unknown</td>
              )}
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Driver</th>
              <td>
                <div className="pb-0 pt-4" style={{ display: 'flex', alignItems: 'center' }}>
                  <FaRegEdit
                    visibility={isEditable ? 'visible' : 'hidden'}
                    className="primary-color pb-1 ps-2 fs-4"
                    onClick={() => setShowModal(true)}
                  />
                </div>
              </td>
              {isChangeActive && (
                <td>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <Spinner animation="border" />
                    <div>Driver change in progress</div>
                  </div>
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {!isChangeActive && (
              <tr>
                <td className="pt-0">
                  <div>{`${formData.driver.name} ${formData.driver.lastName}`}</div>
                  <p>
                    License plate:
                    <span className="primary-color ps-1">{`${formData.driver.licensePlate}`}</span>
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
