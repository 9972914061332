import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import deleteJob from '../api/jobs/deleteJob';
import getJobs from '../api/jobs/getJobs';
import getMapImage from '../api/mapImages/getMapImage';
import { ReactComponent as ArrowDown } from '../assets/caret-down.svg';
import { ReactComponent as ArrowUp } from '../assets/caret-up.svg';
import { ReactComponent as NoJobsImage } from '../assets/no-job.svg';
import Popup from './confirmationModal';
import JobInfo from './jobInfo';
import Search from './jobSearch';
import Banner from './banner';

export default function Jobs(props) {
  const { status, title, onJobChange } = props;
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const loaderRef = useRef(null);
  const currentPage = useRef(0);
  const totalJobRows = useRef(0);
  const jobToDelete = useRef(null);
  const totalJobRowsToReturn = 5;

  async function fetchMapImage(object) {
    if (object.mapImageUrl) {
      return object;
    }
    const token = await getAccessTokenSilently();
    const mapImageUrl = await getMapImage(token, object.jobUuid);
    const updatedObject = { ...object };
    updatedObject.mapImageUrl = mapImageUrl;
    return updatedObject;
  }

  const fetchMapImages = async (objects, clearState) => {
    if (objects) {
      try {
        const fetchPromises = objects.map((object) => fetchMapImage(object));
        const updatedJobList = await Promise.all(fetchPromises);
        setHasMore(totalJobRowsToReturn * currentPage.current < totalJobRows.current);

        if (clearState) {
          setJobs(updatedJobList);
        } else {
          setJobs((prevJobs) => [...prevJobs, ...updatedJobList]);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setHasMore(false);

      if (clearState) {
        setJobs([]);
      }
    }
    setIsLoading(false);
  };

  const getJobObjects = async (clearState) => {
    setIsLoading(true);
    if (clearState) {
      currentPage.current = 1;
    } else {
      currentPage.current += 1;
    }
    const token = await getAccessTokenSilently();
    const jobResponse = await getJobs(
      token,
      status,
      currentPage.current,
      totalJobRowsToReturn,
      search,
    );
    if (jobResponse) {
      totalJobRows.current = jobResponse.totalRows;
      fetchMapImages(jobResponse.jobs, clearState);
    } else {
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (isLoading) return;
    await getJobObjects();
  }, [currentPage, isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchData();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [fetchData]);

  useEffect(() => {
    getJobObjects(true);
  }, []);

  const handleDelete = (job) => {
    jobToDelete.current = job;
    setShowModal(true);
  };

  const getJobComponents = () => {
    if (jobs) {
      return jobs.map((job) => <JobInfo key={job.jobUuid} job={job} onDelete={handleDelete} />);
    }
    return <div />;
  };

  const handleDeleteConfirmation = async () => {
    const token = await getAccessTokenSilently();
    const result = await deleteJob(token, jobToDelete.current.jobUuid);
    if (result) {
      getJobObjects(true);
      onJobChange();
      setShowModal(false);
    }
  };

  const handleSearchFieldChange = (searchObject) => {
    const updatedSearch = [...search];
    const index = updatedSearch.findIndex((item) => item.name === searchObject.name);
    if (index === -1) {
      updatedSearch.push(searchObject);
    } else {
      updatedSearch[index] = searchObject;
    }
    setSearch(updatedSearch);
  };

  const handleSearch = async () => {
    getJobObjects(true);
  };

  return (
    <>
      <Popup
        show={showModal}
        title="Are u sure"
        body="Delete the job?"
        secondaryCaption="Cancel"
        primaryCaption="Delete"
        onClose={() => setShowModal(false)}
        onContinue={handleDeleteConfirmation}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <h2>{title}</h2>
        <Button onClick={() => setIsSearchOpen(!isSearchOpen)} variant="inverse" key={status}>
          {isSearchOpen ? '' : 'Search'}
          {isSearchOpen && <ArrowUp />}
          {!isSearchOpen && <ArrowDown className="button-icon-right" />}
        </Button>
      </div>
      <Search
        onClick={handleSearch}
        onChange={handleSearchFieldChange}
        isSearchOpen={isSearchOpen}
      />
      <div style={{ marginTop: '20px' }}>
        <Banner />
      </div>
      <ListGroup className="my-2 mt-4">
        {jobs.length > 0 && (
          <>
            <div>{getJobComponents()}</div>
            {hasMore && !isLoading && <div ref={loaderRef} className="mt-03" />}
          </>
        )}
        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Spinner animation="border" />
          </div>
        )}
      </ListGroup>
      {jobs.length === 0 && !isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NoJobsImage style={{ width: 28, height: 34 }} />
          <span className="no-jobs">No jobs yet!</span>
        </div>
      )}
    </>
  );
}
