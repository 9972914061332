export default async function capitalizeArray(array) {
  const capitalizedKeysArray = array.map((obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      acc[capitalizedKey] = value;
      return acc;
    }, {});
  });
  return capitalizedKeysArray;
}
