import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  job: {},
};
// eslint-disable-next-line import/prefer-default-export
export const useJobStore = create(
  persist(
    (set) => ({
      ...initialState,
      updateJob: (object) => set({ job: object }),
      resetJob: () => set(initialState),
    }),
    {
      name: 'job',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
