import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import getCompany from '../../api/companies/getCompany';
import postCompany from '../../api/companies/postCompany';
import getJob from '../../api/jobs/getJob';
import updateJob from '../../api/jobs/putJob';
import { ReactComponent as LeftArrow } from '../../assets/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../assets/arrow-right.svg';
import Header from '../../components/header';
import { countries } from '../../util/countries';
import { regex } from '../../util/regex';
import CompanyInfo from './components/companyInfo';
import CompanyLocation from './components/companyLocation';
import OrderDetails from './components/orderDetails';
import VehicleDetails from './components/vehicleDetails';
import Wizard from './components/wizard';
import { isValid } from './util/isValid';

const defaultLength = 200;

const languages = ['Nederlands', 'Deutsch', 'Polska', 'Français', 'Română', 'Espanol', 'English'];

export default function Order() {
  const navigate = useNavigate();
  const { id } = useParams();
  const initialState = {
    id,
    price: null,
    name: '',
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
    vatNumber: '',
    email: '',
    companyFrom: {
      name: null,
      street: null,
      houseNumber: null,
      zipcode: null,
      city: null,
      country: null,
      email: null,
      language: 'Nederlands',
      contactPerson: null,
      telephone: null,
      openingHours: [],
    },
    companyTo: {
      name: null,
      street: null,
      houseNumber: null,
      zipcode: null,
      city: 'null',
      country: null,
      email: null,
      language: 'Nederlands',
      contactPerson: null,
      telephone: null,
      openingHours: [],
    },
    vehicles: [],
    readyFrom: new Date(),
    additionalInfo: '',
    isAccepted: false,
  };

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ initialState });
  const [showValidation, setShowValidation] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchCompany = async () => {
    const token = await getAccessTokenSilently();
    if (step === 1) {
      const job = await getJob(token, id);
      const companyObject = await getCompany(token);
      if (job) {
        setFormData(job);
      }
      if (companyObject.name) {
        const updatedFormData = { ...job };
        updatedFormData.country = companyObject.country;
        updatedFormData.street = companyObject.street;
        updatedFormData.houseNumber = companyObject.houseNumber;
        updatedFormData.zipcode = companyObject.zipcode;
        updatedFormData.city = companyObject.city;
        updatedFormData.email = companyObject.email;
        updatedFormData.name = companyObject.name;
        updatedFormData.vatNumber = companyObject.vatNumber;
        setFormData(updatedFormData);
        setStep(2);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    fetchCompany();
  }, []);

  const getAction = () => {
    if (step === 2) {
      return 'vehicleLocation';
    }
    if (step === 3) {
      return 'vehicleDestination';
    }
    if (step === 4) {
      return 'vehicleDetails';
    }
    if (step === 5) {
      return 'orderDetails';
    }
    return '';
  };

  const handleNextStep = async () => {
    if (!isValid(formData, step, regex)) {
      setShowValidation(true);
      window.scrollTo(0, 0);
    } else {
      const token = await getAccessTokenSilently();
      setShowValidation(false);
      if (step === 1) {
        const responseOk = await postCompany(token, formData);
        if (responseOk) {
          setStep((prevStep) => prevStep + 1);
        } else {
          console.error('Failed to save company details');
        }
      } else {
        const action = getAction();
        const jobId = await updateJob(token, formData, action);
        if (jobId && action === 'orderDetails') {
          navigate('/');
        } else if (jobId) {
          setStep((prevStep) => prevStep + 1);
        } else {
          console.error('Something whent wrong, please try again later');
        }
      }
    }
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [e.target.id]: value,
    }));
  };

  const handleObjectChange = (key, value) => {
    const updatedForm = { ...formData };
    updatedForm[key] = value;
    setFormData(updatedForm);
  };

  return (
    <>
      <Header first="New" second=" Order" />
      <Container>
        <Wizard step={step} />
        <Form>
          {step === 1 && (
            <div className="mt-3">
              <CompanyInfo
                countries={countries}
                showValidation={showValidation}
                onChange={handleChange}
                defaultLength={defaultLength}
                formData={formData}
              />
            </div>
          )}
          {step === 2 && (
            <div className="mt-3">
              <CompanyLocation
                countries={countries}
                languages={languages}
                showValidation={showValidation}
                onChange={handleObjectChange}
                onObjectChange={handleObjectChange}
                defaultLength={defaultLength}
                company={formData.companyFrom}
                title="Vehicle location"
                keyName="companyFrom"
              />
            </div>
          )}
          {step === 3 && (
            <div className="mt-3">
              <CompanyLocation
                countries={countries}
                languages={languages}
                showValidation={showValidation}
                onChange={handleObjectChange}
                onObjectChange={handleObjectChange}
                defaultLength={defaultLength}
                company={formData.companyTo}
                title="Vehicle destination"
                keyName="companyTo"
              />
            </div>
          )}
          {step === 4 && (
            <div className="mt-3">
              <VehicleDetails
                showValidation={showValidation}
                onChange={handleObjectChange}
                defaultLength={defaultLength}
                formData={formData}
                key="companyTo"
              />
            </div>
          )}
          {step === 5 && (
            <div className="mt-3">
              <OrderDetails
                onChange={handleChange}
                formData={formData}
                showValidation={showValidation}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 50,
            }}
          >
            {step > 1 && (
              <Button variant="inverse" onClick={handlePrevStep}>
                <LeftArrow className="button-icon-left" />
                Previous
              </Button>
            )}
            {step < 5 && (
              <Button variant="primary" onClick={handleNextStep}>
                Next step
                <RightArrow className="button-icon-right" />
              </Button>
            )}
            {step === 5 && (
              <Button variant="primary" onClick={handleNextStep}>
                Post job
                <RightArrow className="button-icon-right" />
              </Button>
            )}
          </div>
        </Form>
      </Container>
    </>
  );
}
