import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { vehicleTypes } from '../util/vehicleTypes';
import SelectObjectsComponent from './selectObjects';

export default function Vehicle(props) {
  const { vehicle, onChangeVehicle, onClickAddVehicle } = props;
  const [isChanged, setIsChanged] = useState(false);

  const updatedVehicle = vehicle;

  useEffect(() => {
    onChangeVehicle(updatedVehicle);
  }, [isChanged]);

  return (
    <div
      className="light-background mobile-flex-col"
      style={{
        padding: 10,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 20,
        width: '100%',
      }}
    >
      <div
        className="mobile-flex-col"
        style={{
          padding: 10,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 20,
          width: '100%',
          flex: 4,
        }}
      >
        <div>
          <div className="mb-2">Select a car type</div>
          <SelectObjectsComponent
            value={vehicle.vehicleType || 'Standard'}
            data={vehicleTypes}
            key="vehicle"
            onChange={(e) => {
              updatedVehicle.vehicleType = e;
              setIsChanged(!isChanged);
            }}
          />
        </div>
        <div>
          <div className="mb-2">Amount of cars</div>
          <Form.Control
            type="number"
            placeholder="Amount"
            value={vehicle.amount}
            onChange={(e) => {
              updatedVehicle.amount = e.target.value;
              setIsChanged(!isChanged);
            }}
            min={1}
          />
        </div>
        <div className="d-flex">
          <Form.Check
            type="switch"
            id="electric"
            label="Electric / Hybrid"
            key="electric"
            checked={vehicle.electric}
            onChange={() => {
              updatedVehicle.electric = !updatedVehicle.electric;
              setIsChanged(!isChanged);
            }}
            className="vertical-check"
          />
          <Form.Check
            type="switch"
            id="non-runner"
            label="Non runner"
            key="non-runner"
            checked={vehicle.nonRunner}
            onChange={() => {
              updatedVehicle.nonRunner = !updatedVehicle.nonRunner;
              setIsChanged(!isChanged);
            }}
            className="vertical-check"
          />
        </div>
      </div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <Button
          variant={vehicle.vehicleType ? 'primary' : 'default'}
          disabled={!vehicle.vehicleType}
          className="mt-2 mb-2"
          onClick={() => {
            if (vehicle.vehicleType) {
              onClickAddVehicle();
            }
          }}
        >
          Add vehicle
        </Button>
      </div>
    </div>
  );
}
