import getCurrentDateOrDateInFuture from '../../../util/getCurrentDateOrDateInFuture';
import { isDateAfterDate } from '../../../util/isDateAfterDate';
import { isDateWithinXdaysFromDate } from '../../../util/isDateWithinXDays';
// eslint-disable-next-line import/prefer-default-export
export const isValid = (formData, readyFrom, areDriverDocumentsRequired) => {
  let isDataValid = true;
  formData.isPickupDateValid = true;
  formData.isDeliveryDateValid = true;
  formData.isDriverValid = true;
  formData.isDriverDocumentValid = true;

  const dateForCompare = getCurrentDateOrDateInFuture(readyFrom);

  if (!isDateWithinXdaysFromDate(dateForCompare, formData.pickupDate, 6)) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }
  if (!isDateWithinXdaysFromDate(dateForCompare, formData.deliveryDate, 6)) {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!isDateAfterDate(dateForCompare, formData.pickupDate)) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }
  if (!isDateAfterDate(formData.pickupDate, formData.deliveryDate)) {
    isDataValid = false;
    formData.isPickupDateValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!isDateAfterDate(dateForCompare, formData.deliveryDate)) {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!formData.driver) {
    isDataValid = false;
    formData.isDriverValid = false;
  }
  if (areDriverDocumentsRequired) {
    if (formData.driver && (!formData.driver.documents || formData.driver.documents.length === 0)) {
      isDataValid = false;
      formData.isDriverDocumentValid = false;
    }
  }

  return isDataValid;
};
