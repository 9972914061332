import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import getCompanies from '../../../api/companies/getCompanies';

export default function CompanyAddressBook(props) {
  const { onClickClose, showModal, onClickCompany, city, country } = props;
  const [companies, setCompanies] = useState([]);
  //   const [search, setSearch] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { getAccessTokenSilently } = useAuth0();

  const fetchCompanies = async () => {
    const token = await getAccessTokenSilently();
    const companyResponse = await getCompanies(token, null, currentPage, city, country);
    const updatedCompanies = companyResponse.companies;
    setCompanies((prevCompanies) => [...prevCompanies, ...updatedCompanies]);
    setHasMore(updatedCompanies.length + companies.length < companyResponse.totalRows);
  };

  const getCompanyComponents = () => {
    return companies.map((company) => {
      return (
        <div
          key={company.companyId}
          style={{
            height: 'fit-content',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
            marginTop: 5,
            marginBottom: 5,
            padding: 10,
          }}
        >
          <div>
            <div style={{ fontWeight: 'bold' }}>{company.name}</div>
            <div>{`${company.street} ${company.houseNumber}`}</div>
            <div>{company.zipcode}</div>
            <div>{`${company.city}, ${company.country}`}</div>
          </div>
          <Button onClick={() => onClickCompany(company)} style={{ marginLeft: 5 }}>
            Select
          </Button>
        </div>
      );
    });
  };

  useEffect(() => {
    fetchCompanies();
  }, [currentPage]);

  return (
    <Modal show={showModal} onHide={() => onClickClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Company address book</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {companies.length === 0 && <div>No previous used companies for this city yet</div>}
        <div
          id="scrollableDiv"
          style={{
            height: 300,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <InfiniteScroll
            dataLength={companies.length}
            next={() => {
              setCurrentPage(currentPage + 1);
            }}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            {getCompanyComponents()}
          </InfiniteScroll>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClickClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
