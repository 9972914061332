// translate the values in polish and make a new object with pl

export default function getResources() {
  return {
    en: {
      translation: {
        title: 'Multi-language app',
        label: 'Select another language!',
        about: 'About',
        home: 'Home!',
        homeClient: {
          title: 'Orders',
        },
      },
    },
  };
}
