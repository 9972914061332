// eslint-disable-next-line import/prefer-default-export
export const isValid = (name, lastName) => {
  let isDataValid = true;

  if (name === '' || lastName === '') {
    isDataValid = false;
  }

  return isDataValid;
};
