// eslint-disable-next-line import/prefer-default-export
export function isDateWithinXdaysFromDate(fromDate, date, days) {
  if (!date || !fromDate) {
    return false;
  }
  const firstDate = new Date(fromDate).getTime();
  const secondDate = new Date(date).getTime();

  // if (firstDate > secondDate) {
  //   return false;
  // }

  const daysInMillis = days * 24 * 60 * 60 * 1000;
  const diff = Math.abs(firstDate - secondDate);
  return diff <= daysInMillis;
}
