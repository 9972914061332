import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useJobStore } from '../setup/stores/jobStore';
import useUserStore from '../setup/stores/userStore';

export default function LogoutButton() {
  const { logout } = useAuth0();
  const [reset] = useUserStore((state) => [state.reset]);
  const [resetJob] = useJobStore((state) => [state.resetJob]);

  return (
    <NavDropdown.Item
      onClick={() => {
        reset();
        resetJob();
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
    >
      Logout
    </NavDropdown.Item>
  );
}
