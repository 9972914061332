export default async function patchJob(token, id, pickupDate) {
  const data = {
    JobUuid: id,
    PickupDate: pickupDate,
  };

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/jobs/pickup`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      return response.ok;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
