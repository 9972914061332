import getCurrentDateOrDateInFuture from '../../../util/getCurrentDateOrDateInFuture';
import { isDateAfterDate } from '../../../util/isDateAfterDate';
import { isDateWithinXdaysFromDate } from '../../../util/isDateWithinXDays';
// eslint-disable-next-line import/prefer-default-export
export const isValid = (formData, job) => {
  let isDataValid = true;
  formData.isDeliveryDateValid = true;

  if (formData.deliveryDate === '') {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!formData.isAccepted) {
    isDataValid = false;
  }

  if (!isDateAfterDate(job.pickupDate, formData.deliveryDate)) {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!isDateAfterDate(job.readyFrom, formData.deliveryDate)) {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  if (!isDateAfterDate(formData.deliveryDate, new Date().setUTCHours(0, 0, 0, 0))) {
    isDataValid = false;
    formData.isPickupDateValid = false;
  }

  if (
    !isDateWithinXdaysFromDate(
      getCurrentDateOrDateInFuture(job.readyFrom),
      formData.deliveryDate,
      21,
    )
  ) {
    isDataValid = false;
    formData.isDeliveryDateValid = false;
  }

  return isDataValid;
};
