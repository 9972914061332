import React from 'react';
import Table from 'react-bootstrap/Table';

export default function VehicleDetails(props) {
  const { formData } = props;

  const getTypeCaption = (vehicleType) => {
    if (vehicleType === 'Standard') {
      return 'Standard';
    }
    if (vehicleType === 'Suv') {
      return 'Suv';
    }
    if (vehicleType === 'VanSmall') {
      return 'Bus (max. L1H1)';
    }
    return 'Bus (max. L3H3)';
  };

  const getVehicles = () => {
    return formData.vehicles.map((vehicle) => {
      return (
        <tr key={vehicle.vehicleId}>
          <td className="ps-0 py-3">{vehicle.chassisNumber}</td>
          <td className="ps-0 py-3">{vehicle.model}</td>
          <td className="ps-0 py-3">{vehicle.value}</td>
          <td className="ps-0 py-3">{getTypeCaption(vehicle.vehicleType)}</td>
          <td className="ps-0 py-3">{vehicle.electric ? 'Yes' : 'No'}</td>
          <td className="ps-0 py-3">{vehicle.nonRunner ? 'Yes' : 'No'}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <h3 className="mb-4">Verhicle details</h3>
      <div className="p-4 pt-3 light-background" style={{ minWidth: 'fit-content' }}>
        <Table className="darker-color mb-0">
          <thead>
            <tr>
              <th className="ps-0 py-3">Chassis number</th>
              <th className="ps-0 py-3">Model</th>
              <th className="ps-0 py-3">Value</th>
              <th className="ps-0 py-3">Type</th>
              <th className="ps-0 py-3">Electric</th>
              <th className="ps-0 py-3">Non runner</th>
            </tr>
          </thead>
          <tbody>{getVehicles()}</tbody>
        </Table>
      </div>
    </div>
  );
}
