import React from 'react';
import Form from 'react-bootstrap/Form';
import SelectComponent from '../../../components/select';
import { countries } from '../../../util/countries';
import CityDropdown from './placesAutocomplete';

function Location(props) {
  const {
    countryFrom,
    onChangeCountryFrom,
    countryTo,
    onChangeCountryTo,
    onChangeCityFrom,
    onChangeCityTo,
  } = props;
  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Locations</h2>
      <span>Please provide us with the pickup and delivery locations.</span>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 20, marginTop: 50 }}>
        <div style={{ flex: 1, width: '50%' }}>
          <h4>From</h4>
          <Form.Group className="mt-3" controlId="formFromCountry">
            <div style={{ width: '100%' }}>
              <SelectComponent
                value={countryFrom.code || 'Pick a country'}
                defaultTitle="Pick a country"
                data={countries}
                onSelect={(e) => onChangeCountryFrom(e)}
                key="formFromCountry"
              />
            </div>
          </Form.Group>
          <Form.Group className="mt-3" controlId="formFromCity">
            <div style={{ width: '100%' }}>
              <CityDropdown
                onPlaceSelected={onChangeCityFrom}
                countryFilter={countryFrom.code}
                disabled={!countryFrom.code}
                classes="cityFrom"
              />
            </div>
          </Form.Group>
        </div>
        <div style={{ flex: 1, width: '50%' }}>
          <h4>To</h4>
          <Form.Group className="mt-3" controlId="formNaarLand">
            <div style={{ width: '100%' }}>
              <SelectComponent
                value={countryTo.code || 'Pick a country'}
                defaultTitle="Pick a country"
                data={countries}
                onSelect={(e) => onChangeCountryTo(e)}
                key="formToCountry"
              />
            </div>
          </Form.Group>
          <Form.Group className="mt-3" controlId="formToCity">
            <div style={{ width: '100%' }}>
              <CityDropdown
                onPlaceSelected={onChangeCityTo}
                countryFilter={countryTo.code}
                disabled={!countryTo.code}
                classes="cityTo"
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default Location;
