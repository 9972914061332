// eslint-disable-next-line import/prefer-default-export
export function isDateAfterDate(firstDate, secondDate) {
  if (!firstDate || !secondDate) {
    return false;
  }
  const firstDateTime = new Date(firstDate).getTime();
  const secondDateTime = new Date(secondDate).getTime();

  return firstDateTime <= secondDateTime;
}
