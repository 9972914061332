import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function OrderDetails(props) {
  const { onChange, formData, showValidation } = props;

  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Company info</h2>
      <h6>We need additional information to complete your order</h6>
      <Row>
        <div className="col-lg-6 col-12">
          <Row>
            <Form.Group controlId="readyFrom">
              <Form.Label>Ready from</Form.Label>
              <Form.Control
                type="date"
                value={formData.readyFrom ?? ''}
                onChange={onChange}
                required
                min={new Date().toISOString().split('T')[0]}
                isInvalid={
                  showValidation &&
                  (!formData.readyFrom ||
                    new Date(formData.readyFrom).setHours(23, 59, 59, 999) < new Date())
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="additionalInfo">
              <Form.Label>Additional information</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.additionalInfo ?? ''}
                onChange={onChange}
                maxLength="2000"
              />
            </Form.Group>
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 20 }}>
            <Form.Group controlId="isAccepted" className="mt-2">
              <Form.Check
                type="checkbox"
                label="I accept the "
                checked={formData.isAccepted}
                onChange={onChange}
                required
                isInvalid={showValidation && !formData.isAccepted}
              />
            </Form.Group>
            <a href="/Terms and Conditions Transporting Highway.pdf" download>
              Terms and Conditions
            </a>
          </div>
        </div>
      </Row>
    </div>
  );
}
