export default function getWeekSchedule() {
  return [
    {
      dayNumber: 1,
      name: 'Monday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: false,
    },
    {
      dayNumber: 2,
      name: 'Tuesday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: false,
    },
    {
      dayNumber: 3,
      name: 'Wednesday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: false,
    },
    {
      dayNumber: 4,
      name: 'Thursday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: false,
    },
    {
      dayNumber: 5,
      name: 'Friday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: false,
    },
    {
      dayNumber: 6,
      name: 'Saturday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: true,
    },
    {
      dayNumber: 7,
      name: 'Sunday',
      from: '08:00',
      till: '18:00',
      minFrom: '07:00',
      maxTill: '22:00',
      isClosed: true,
    },
  ];
}
