import React from 'react';
import Form from 'react-bootstrap/Form';
import { ReactComponent as Bug } from '../../../assets/bug.svg';
import { ReactComponent as Electric } from '../../../assets/ev-front.svg';

export default function CalculationResult(props) {
  const {
    price,
    discount,
    distance,
    countryFrom,
    countryTo,
    cityFrom,
    cityTo,
    vehicles,
    isAccepted,
    setIsAccepted,
    showValidation,
  } = props;

  const style = {
    label: {
      width: 150,
    },
  };

  const getVehicleTitle = (vehicle) => {
    const { vehicleType, electric, nonRunner } = vehicle;
    const vehicleTypePart = vehicleType;
    const electricPart = electric ? (
      <Electric style={{ marginLeft: 5, marginRight: 1 }} />
    ) : (
      <div />
    );
    const nonRunnerPart = nonRunner ? <Bug style={{ marginLeft: 5, marginRight: 1 }} /> : <div />;
    const title = (
      <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <div>{`${vehicleTypePart}`}</div>
        {electricPart}
        {nonRunnerPart}
      </div>
    );
    return title;
  };

  const getVehicleComponents = () => {
    return vehicles.map((vehicle) => {
      const amount = vehicle.amount > 1 ? `x${vehicle.amount}` : '';
      return (
        <div key={vehicle.vehicleId} style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            {getVehicleTitle(vehicle)}
            <div>{`${amount}:`}</div>
          </div>
          <div style={{ marginLeft: 5 }} className="secondary-color">
            {` €${vehicle.transportPrice},-`}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Calculation</h2>
      <div className="light-background" style={{ padding: 10, marginTop: 50 }}>
        <div style={{ display: 'flex' }}>
          <div className="secondary-color" style={style.label}>
            From:
          </div>
          <div>{`${cityFrom.name}, ${countryFrom.name}`}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="secondary-color" style={style.label}>
            To:
          </div>
          <div>{`${cityTo.name}, ${countryTo.name}`}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="secondary-color" style={style.label}>
            Distance:
          </div>
          <div>{`${distance} km`}</div>
        </div>
        {price && (
          <>
            <div style={{ display: 'flex' }}>
              <div className="secondary-color" style={style.label}>
                Vehicles:
              </div>
              <div>{getVehicleComponents()}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
              <div style={{ width: 150, fontWeight: 600 }}>Our lowest price:</div>
              <div style={{ fontSize: 20, fontWeight: 'bold' }} className="primary-color">
                {` €${price},- (ex VAT)`}
              </div>
            </div>
            {discount > 0 && (
              <div style={{ display: 'flex' }}>
                <div className="secondary-color" style={style.label}>
                  Discount:
                </div>
                <div>{` €${discount},-`}</div>
              </div>
            )}
            {price && (
              <div className="mt-2">If you see a lower price elswhere, please contact us!</div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 20 }}>
              <Form.Group controlId="isAccepted" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="I accept the "
                  checked={isAccepted}
                  onChange={() => setIsAccepted(!isAccepted)}
                  required
                  isInvalid={showValidation && !isAccepted}
                />
              </Form.Group>
              <a href="/Terms and Conditions Transporting Highway.pdf" download>
                Terms and Conditions
              </a>
            </div>
          </>
        )}
        {!price && (
          <div className="mt-5">
            There is not a price for this route yet. Please contact us to receive the lowest price.
          </div>
        )}
      </div>
    </div>
  );
}
