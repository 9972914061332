export default async function postDriverDocument(token, driver) {
  const data = {
    Name: driver.name,
    LastName: driver.lastName,
  };

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/drivers`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      const driverId = await response.text();
      return driverId;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
