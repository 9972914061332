import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Popup(props) {
  const { title, body, secondaryCaption, primaryCaption, onClose, onContinue, show } = props;

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {secondaryCaption && (
          <Button variant="default" onClick={() => onClose()}>
            {secondaryCaption}
          </Button>
        )}
        <Button variant="primary" onClick={() => onContinue()}>
          {primaryCaption}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
