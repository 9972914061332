import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { BsPlus } from 'react-icons/bs';
import deleteJobDocument from '../../../api/job-documents/deleteJobDocument';
import getJobDocument from '../../../api/job-documents/getJobDocument';
import { ReactComponent as DeleteLogo } from '../../../assets/delete.svg';
import { ReactComponent as FileLogo } from '../../../assets/file.svg';
import FileUploadModal from '../../../components/fileUploadModal';
import Popup from '../../../components/confirmationModal';

export default function Files(props) {
  const {
    title,
    onChange,
    jobId,
    files,
    isReadOnly,
    isJobOpen,
    documentType,
    isTransporter,
    isChangeActive,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fileToDeleteRef = useRef(null);

  useEffect(() => {
    setDocuments(files);
  }, [files]);

  const downloadFile = async (file) => {
    const token = await getAccessTokenSilently();
    const url = await getJobDocument(token, file.documentId, true);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteFileConfirmation = async () => {
    const token = await getAccessTokenSilently();
    deleteJobDocument(token, fileToDeleteRef.current.documentId);
    const updatedFiles = documents.filter(
      (object) => object.documentId !== fileToDeleteRef.current.documentId,
    );
    setDocuments(updatedFiles);
    setShowDeleteModal(false);
    fileToDeleteRef.current = null;
  };

  const handleDeleteFile = async (file) => {
    fileToDeleteRef.current = file;
    setShowDeleteModal(true);
  };

  const getRows = () => {
    if (documents.map) {
      return documents.map((file) => {
        return (
          <tr key={file.documentId}>
            <td className="py-3 ps-0 pe-3">
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                className="mobile-flex-col"
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <div
                    style={{
                      background: 'white',
                      width: 74,
                      height: 74,
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                      display: 'flex',
                    }}
                  >
                    <FileLogo />
                  </div>

                  {file.name}
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 170 }}>{file.documentType}</div>
                  {file.isDeleteAllowed && isJobOpen && (
                    <Button
                      variant="link"
                      style={{ marginLeft: 15 }}
                      onClick={() => handleDeleteFile(file)}
                    >
                      <DeleteLogo />
                    </Button>
                  )}
                  <Button variant="inverse" onClick={() => downloadFile(file)}>
                    Download
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  const handleFileChange = () => {
    setShowModal(false);
    onChange();
  };

  return (
    <div>
      <Popup
        show={showDeleteModal}
        title="Confirm deletion"
        body="Are you sure you want to delete this file?"
        secondaryCaption="Cancel"
        primaryCaption="Delete"
        onClose={() => setShowDeleteModal(false)}
        onContinue={() => handleDeleteFileConfirmation()}
      />
      <div className="mb-3 mt-3">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3 className="mb-4">{title}</h3>
        </div>
        <div className="p-4 light-background">
          {documents.length > 0 && (
            <Table>
              <tbody>
                {isChangeActive && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <Spinner animation="border" />
                    <div>Document change in progress</div>
                  </div>
                )}
                {!isChangeActive && getRows()}
              </tbody>
            </Table>
          )}
          {!isReadOnly && (
            <Button
              onClick={() => setShowModal(true)}
              className="mt-2"
              style={{ width: 'fitContent' }}
            >
              {isTransporter ? 'Add CMR' : 'Add documents'}
              <BsPlus style={{ fontSize: 24, marginBottom: 3 }} />
            </Button>
          )}
        </div>
      </div>

      <FileUploadModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        documentType={documentType}
        objectId={jobId}
        type="job"
        onFileChange={handleFileChange}
      />
    </div>
  );
}
