export default async function getRoles(token, email) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/roles?email=${email}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        },
      );
      const jsonData = await response.json();
      if (response.ok && jsonData.length > 0) {
        const roles = jsonData.map((role) => role.Name ?? role);
        return roles;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
