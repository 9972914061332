import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CarImage } from '../assets/car.svg';
import { ReactComponent as CheckImage } from '../assets/check-double-line.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { ReactComponent as FlagImage } from '../assets/flag.svg';
import { useJobStore } from '../setup/stores/jobStore';
import useUserStore from '../setup/stores/userStore'; // Corrected import statement
import decapitalizeArray from '../util/decapitalizeArray';
import getJobTagColor from '../util/getJobTagColor';

const style = {
  listViewDetailsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
    minWidth: 250,
  },
  text: {
    label: { display: 'flex', width: 100 },
    header: { fontWeight: 600, display: 'flex', alignItems: 'center' },
    big: { fontSize: 18, fontWeight: 600 },
    extraBig: { fontSize: 20, fontWeight: 600 },
  },
  labelContainer: {
    display: 'flex',
    gap: 5,
    justifyContent: 'flex-start',
  },
};

export default function JobInfo(props) {
  const { job, onDelete } = props;
  const [vehicles, setVehicles] = useState([]);
  const [isExtraInfoOpen, setIsExtraInfoOpen] = useState(false);
  const navigate = useNavigate();
  const [updateJob] = useJobStore((state) => [state.updateJob]);
  const [isTransporter, isBlocked] = useUserStore((state) => [
    state.isTransporter,
    state.isBlocked,
  ]);

  const getVehicles = async () => {
    if (job.vehicles) {
      const vehicleObjects = await JSON.parse(job.vehicles);
      setVehicles(await decapitalizeArray(vehicleObjects));
    }
  };

  const getVehiclesComponents = () => {
    return vehicles.map((vehicle) => {
      return (
        <div style={style.labelContainer} key={vehicle.vehicleId}>
          <div className="secondary-color" style={style.text.label}>
            Car:
          </div>
          <div>
            <div>{`${vehicle.model}`}</div>
            {vehicle.chassisNumber && <div>{`${vehicle.chassisNumber.slice(-5)}`}</div>}
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <ListGroup.Item
      key={job.jobUuid}
      className="mb-3 light-background"
      style={{ borderRadius: 5, border: 'none' }}
    >
      <Row>
        <div
          key={job.jobUuid}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 15,
          }}
        >
          <Col>
            <img alt="map" src={job.mapImageUrl} className="map-image" />
          </Col>
          <Col>
            <div style={{ display: 'flex', gap: 10, flexWrap: 'nowrap', height: 35 }}>
              <div className="secondary-color" style={{ display: 'flex', gap: 10 }}>
                Order ID:
              </div>
              <div>{job.orderNumber}</div>
              <div style={{ marginLeft: 15 }}>{`${job.distance} km`}</div>
            </div>
            <div style={style.listViewDetailsContainer}>
              <div style={style.text.header}>
                <FlagImage className="text-icon-left" />
                Pickup
              </div>
              <div style={style.labelContainer}>
                <div className="secondary-color" style={style.text.label}>
                  Location:
                </div>
                <div>{`${job.cityFrom}, ${job.countryFrom}`}</div>
              </div>
              {job.pickupDate && (
                <div style={style.labelContainer}>
                  <div className="secondary-color" style={style.text.label}>
                    Pickup date:
                  </div>
                  <div>
                    {new Date(job.pickupDate).toLocaleDateString('nl-NL', { timeZone: 'UTC' })}
                  </div>
                </div>
              )}
              {job.readyFrom && (
                <div style={style.labelContainer}>
                  <div className="secondary-color" style={style.text.label}>
                    Ready from:
                  </div>
                  <div>
                    {new Date(job.readyFrom).toLocaleDateString('nl-Nl', { timeZone: 'UTC' })}
                  </div>
                </div>
              )}
              {job.zipCodeFrom && (
                <div style={style.labelContainer}>
                  <div className="secondary-color" style={style.text.label}>
                    ZIP code:
                  </div>
                  <div>{job.zipCodeFrom}</div>
                </div>
              )}
            </div>
          </Col>
          <Col>
            <div style={{ height: 35 }} />
            <div style={style.listViewDetailsContainer}>
              <div style={style.text.header}>
                <FlagImage className="text-icon-left" />
                Delivery
              </div>
              <div style={style.labelContainer}>
                <div className="secondary-color" style={style.text.label}>
                  Location:
                </div>
                <div>{`${job.cityTo}, ${job.countryTo}`}</div>
              </div>
              {job.deliveryDate && (
                <div style={style.labelContainer}>
                  <div className="secondary-color" style={style.text.label}>
                    Delivery date:
                  </div>
                  <div>
                    {new Date(job.deliveryDate).toLocaleDateString('nl-NL', { timeZone: 'UTC' })}
                  </div>
                </div>
              )}
              <div style={style.labelContainer}>
                <div className="secondary-color" style={style.text.label}>
                  Status:
                </div>
                <div
                  className={`tag ${getJobTagColor(job.status)}`}
                  style={{ whiteSpace: 'nowrap', fontSize: '10px' }}
                >
                  {job.status === 'ToPlan' ? 'To plan' : job.status}
                </div>
              </div>
              {job.zipCodeTo && (
                <div style={style.labelContainer}>
                  <div className="secondary-color" style={style.text.label}>
                    ZIP code:
                  </div>
                  <div>{job.zipCodeTo}</div>
                </div>
              )}
            </div>
          </Col>
          <Col>
            {job.status !== 'Draft' && (
              <>
                <div style={{ height: 35 }} key={job.jobUuid} />
                <div style={style.listViewDetailsContainer}>
                  <div style={style.text.header}>
                    <CarImage className="text-icon-left" />
                    Vehicles
                  </div>
                  {getVehiclesComponents()}
                </div>
              </>
            )}
          </Col>
          <Col>
            <div
              className="hide-left-border-when-small"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 10,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div>Our price</div>
                <div className="primary-color" style={style.text.extraBig}>
                  {`€ ${Math.round(job.price)},-`}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: 10,
                  gap: 10,
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {job.status === 'Draft' && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      onClick={() => navigate(`/order/${job.jobUuid}`)}
                      className="mt-2 mb-2"
                      variant="primary"
                    >
                      Plan order
                    </Button>
                    <Button
                      variant="link"
                      className="ml-2"
                      onClick={() => onDelete(job)}
                      hover="test"
                    >
                      <DeleteIcon style={{ color: 'red' }} />
                    </Button>
                  </div>
                )}
                {job.status === 'ToPlan' && isTransporter && !isBlocked && (
                  <Button
                    onClick={() => {
                      updateJob({
                        id: job.jobUuid,
                        orderNumber: job.orderNumber,
                        companyFrom: { city: job.cityFrom, country: job.countryFrom },
                        companyTo: { city: job.cityTo, country: job.countryTo },
                        price: job.price,
                        readyFrom: job.readyFrom,
                        areDriverDocumentsRequired: job.areDriverDocumentsRequired,
                      });
                      navigate(`/jobs/${job.jobUuid}/accept`);
                    }}
                    className="mt-2 mb-2"
                    variant="primary"
                  >
                    Take order
                  </Button>
                )}
                {job.status !== 'Draft' && (
                  <Button
                    onClick={() => navigate(`/jobs/${job.jobUuid}`)}
                    className="mt-2 mb-2"
                    variant="inverse"
                  >
                    More details
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </div>
      </Row>
      {job.additionalInfo && (
        <Row className="mt-2">
          <div>
            <div>
              <div style={style.text.header}>
                <CheckImage className="text-icon-left" />
                Extra info
              </div>
              {job.additionalInfo.slice(0, 100)}
              <Collapse in={isExtraInfoOpen} timeout={50}>
                <span>
                  {job.additionalInfo.slice(101, job.additionalInfo.length)}
                  <div
                    className="mt-1"
                    onClick={() => setIsExtraInfoOpen(false)}
                    style={{ hover: 'pointer' }}
                  >
                    {isExtraInfoOpen && <span className="span-with-click">...see less</span>}
                  </div>
                </span>
              </Collapse>
              {!isExtraInfoOpen && job.additionalInfo.length > 100 ? (
                <div onClick={() => setIsExtraInfoOpen(true)}>
                  <span className="span-with-click">...see more</span>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Row>
      )}
    </ListGroup.Item>
  );
}
