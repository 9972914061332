export default async function postDriverDocument(token, file, driverId) {
  const formData = new FormData();
  formData.append('File', file);
  formData.append('DriverId', driverId);
  formData.append('FileName', file.name);

  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/driver-documents`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      );
      return response.ok;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
