import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import getUserInformation from '../../api/user-information/getUserInformation'; // Corrected import path to user stores

const initialState = {
  userObject: null,
  isTransporter: false,
  isClient: false,
  isBlocked: false,
  blockedUntil: '',
  blockedReason: '',
};

const isBlocked = (date) => {
  if (!date) return false;
  return new Date(date).getTime() > new Date().getTime();
};

// eslint-disable-next-line import/prefer-default-export
const useUserStore = create(
  persist(
    (set) => ({
      ...initialState,
      updateIsTransporter: (boolean) => set({ isTransporter: boolean }),
      updateIsClient: (boolean) => set({ isClient: boolean }),
      updateBlockedUntil: async (token) => {
        const userInfo = await getUserInformation(token);
        set({ blockedUntil: userInfo.blockedUntil });
        set({ blockedReason: userInfo.blockedReason });
        set({ isBlocked: isBlocked(userInfo.blockedUntil) });
      },
      reset: () => set(initialState),
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useUserStore;
