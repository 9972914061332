export default async function getJobDocument(token, documentId, IsOriginal) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/job-documents/${documentId}?IsOriginal=${IsOriginal}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const blob = await response.blob();
      const documentURL = URL.createObjectURL(blob);
      return documentURL;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
