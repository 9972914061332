import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Fade from 'react-bootstrap/Fade';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import getCompany from '../../api/companies/getCompany';
import postCompany from '../../api/companies/postCompany';
import deleteDriver from '../../api/drivers/deleteDriver';
import getDrivers from '../../api/drivers/getDrivers';
import Popup from '../../components/confirmationModal';
import Header from '../../components/header';
import useUserStore from '../../setup/stores/userStore';
import { countries } from '../../util/countries';
import { regex } from '../../util/regex';
import DriverDetails from '../driver-details/driverDetails';
import Drivers from './components/drivers';
import { isValid } from './util/isValid';

const languages = ['Nederlands', 'Deutsch', 'Polska', 'Français', 'Română', 'Espanol', 'English'];

export default function CompanyDetails() {
  const [isTransporter] = useUserStore((state) => [state.isTransporter]);
  const [company, setCompany] = useState({
    contactPerson: '',
    name: '',
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: 'Netherlands',
    vatNumber: '',
    email: '',
    language: 'Nederlands',
    companyId: '',
  });
  const [drivers, setDrivers] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const navigate = useNavigate();
  const [isAddDriver, setIsAddDriver] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const selectedDriver = useRef(null);
  const driverToDeleteRef = useRef(null);

  const fetchCompany = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const companyObject = await getCompany(token);
    setCompany(companyObject);
    if (isTransporter) {
      const driverObjects = await getDrivers(token);
      setDrivers(driverObjects);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchCompany();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const getCountryOptions = () => {
    return countries.map((country) => {
      return (
        <option key={country.code} value={country.name}>
          {country.name}
        </option>
      );
    });
  };

  const getLanguageOptions = () => {
    return languages.map((language) => {
      return (
        <option key={language} value={language}>
          {language}
        </option>
      );
    });
  };

  const handleSubmit = async () => {
    if (!isValid(company, regex)) {
      setShowValidation(true);
      window.scrollTo(0, 0);
    } else {
      setShowValidation(false);
      const token = await getAccessTokenSilently();
      const responseOk = await postCompany(token, company);
      if (responseOk) {
        navigate('/');
      } else {
        console.error('Failed to save company details');
      }
    }
  };

  const handleClickAddDriver = () => {
    selectedDriver.current = null;
    setIsAddDriver(true);
  };

  const handleClickDetailsDriver = (driver) => {
    selectedDriver.current = driver;
    setIsAddDriver(true);
  };

  const handleSubmitDriver = async () => {
    setIsLoading(true);
    setIsAddDriver(false);
    const token = await getAccessTokenSilently();
    const driverObjects = await getDrivers(token);
    setDrivers(driverObjects);
    setIsLoading(false);
  };

  const handleDeleteDriver = async (driver) => {
    driverToDeleteRef.current = driver;
    setShowModal(true);
  };

  const handleDeleteDriverConfirmation = async () => {
    const token = await getAccessTokenSilently();
    deleteDriver(token, driverToDeleteRef.current.id);
    const updatedDrivers = drivers.filter((object) => object.id !== driverToDeleteRef.current.id);
    setDrivers(updatedDrivers);
    driverToDeleteRef.current = null;
    setShowModal(false);
  };

  return (
    <div>
      <Popup
        show={showModal}
        title="Confirm deletion"
        body="Are you sure you want to delete this driver?"
        secondaryCaption="Cancel"
        primaryCaption="Delete"
        onClose={() => setShowModal(false)}
        onContinue={() => handleDeleteDriverConfirmation()}
      />
      <Header first="Company" second=" Details" />
      <Fade in={!isLoading}>
        <Container>
          <h2>Company</h2>
          <Form>
            <Row>
              <Col xs={12} md={6} className="mb-4">
                <Form.Group controlId="name">
                  <Form.Label>Company name*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.name ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.name}
                  />
                </Form.Group>
                <Form.Group controlId="contactPerson">
                  <Form.Label>Contact person*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.contactPerson ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.contactPerson}
                  />
                </Form.Group>
                <Form.Group controlId="street">
                  <Form.Label>Street*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.street ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.street}
                  />
                </Form.Group>
                <Form.Group controlId="houseNumber">
                  <Form.Label>Number*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.houseNumber ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.houseNumber}
                  />
                </Form.Group>
                <Form.Group controlId="zipcode">
                  <Form.Label>Zipcode*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.zipcode ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.zipcode}
                  />
                </Form.Group>
                <Form.Group controlId="city">
                  <Form.Label>City*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.city ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.city}
                  />
                </Form.Group>
                <Form.Group controlId="country">
                  <Form.Label>Country*</Form.Label>
                  <Form.Control as="select" value={company.country} onChange={handleChange}>
                    {getCountryOptions()}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="vatNumber">
                  <Form.Label>VAT Number*</Form.Label>
                  <Form.Control
                    type="text"
                    value={company.vatNumber ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && !company.vatNumber}
                  />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control
                    type="email"
                    value={company.email ?? ''}
                    onChange={handleChange}
                    required
                    isInvalid={showValidation && (!company.email || !company.email.match(regex))}
                    maxLength={200}
                  />
                </Form.Group>

                <Form.Group controlId="language">
                  <Form.Label>Contact language*</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    aria-label="language"
                    value={company.language}
                  >
                    {getLanguageOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              {isTransporter && (
                <Col>
                  <Drivers
                    drivers={drivers}
                    onClickAdd={handleClickAddDriver}
                    onClickDetails={handleClickDetailsDriver}
                    onDelete={handleDeleteDriver}
                    companyId={company.companyId}
                  />
                </Col>
              )}
            </Row>
            <Button variant="primary" onClick={() => handleSubmit()} className="mt-3">
              Save
            </Button>
          </Form>
        </Container>
      </Fade>
      {isAddDriver && (
        <DriverDetails
          onSubmit={handleSubmitDriver}
          onClose={() => setIsAddDriver(false)}
          show={isAddDriver}
          title={selectedDriver.current ? 'Driver details' : 'Add driver'}
          driver={selectedDriver.current}
        />
      )}
    </div>
  );
}
