export default async function getCompanies(token, search, page, city, country) {
  if (token) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/companies?search=${search}&page=${page}&city=${city}&country=${country}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        const jsonData = await response.json();
        const companyResponse = { totalRows: jsonData.TotalRows };
        companyResponse.companies = [];
        if (jsonData.Companies) {
          const newCompanies = jsonData.Companies.map((company) => {
            return {
              name: company.Name,
              street: company.Street,
              houseNumber: company.HouseNumber,
              zipcode: company.Zipcode,
              city: company.City,
              country: company.Country,
              vatNumber: company.VatNumber,
              email: company.Email ?? '',
              telephone: company.Telephone ?? '',
              language: company.Language ?? '',
              companyId: company.CompanyId ?? '',
              contactPerson: company.ContactPerson ?? '',
            };
          });
          companyResponse.companies = newCompanies;
        }
        return companyResponse;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
