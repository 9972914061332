import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ReactComponent as SearchLogo } from '../assets/search-line.svg';

const style = {
  input: { maxWidth: 180, minWidth: 150, height: 39 },
  inputSmall: { width: 100 },
};

function Search(props) {
  const { onChange, onClick, isSearchOpen, status } = props;
  const [cityFrom, setCityFrom] = useState('');
  const [cityTo, setCityTo] = useState('');
  const [pickupFrom, setPickupFrom] = useState(new Date());
  const [pickupTill, setPickupTill] = useState(null);
  const [amountFrom, setAmountFrom] = useState(null);
  const [amountTill, setAmountTill] = useState(null);
  const [chassisNumber, setChassisNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const getTimeInMilliseconds = (date) => {
    if (!date) return null;
    const selectedDate = new Date(date);
    const milliseconds = selectedDate.getTime();
    return milliseconds;
  };

  return (
    <div>
      <Collapse in={isSearchOpen}>
        <div>
          <h4>Search</h4>
          <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', gap: 20 }} className="mb-4">
              <Form.Group controlId="orderNumber" style={style.input}>
                <Form.Label>Order ID</Form.Label>
                <Form.Control
                  type="text"
                  value={orderNumber ?? ''}
                  onChange={(e) => {
                    setOrderNumber(e.target.value);
                    onChange({
                      name: 'OrderNumber',
                      value: e.target.value,
                      type: 'Compare',
                    });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onClick();
                    }
                  }}
                  maxLength={40}
                />
              </Form.Group>
            </div>
            <div style={{ display: 'flex', gap: 20 }} className="mb-4">
              <Form.Group controlId="cityFrom" style={style.input}>
                <Form.Label>City from </Form.Label>
                <Form.Control
                  type="text"
                  value={cityFrom ?? ''}
                  onChange={(e) => {
                    setCityFrom(e.target.value);
                    onChange({ name: 'CityFrom', value: e.target.value, type: 'Compare' });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onClick();
                    }
                  }}
                  maxLength={40}
                />
              </Form.Group>
              <Form.Group controlId="cityTo" style={style.input}>
                <Form.Label>City to </Form.Label>
                <Form.Control
                  type="text"
                  value={cityTo ?? ''}
                  onChange={(e) => {
                    setCityTo(e.target.value);
                    onChange({ name: 'CityTo', value: e.target.value, type: 'Compare' });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onClick();
                    }
                  }}
                  maxLength={40}
                />
              </Form.Group>
            </div>
            <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
              <Form.Group controlId="pickupDateRange">
                <Form.Label>Pickup date</Form.Label>
                <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                  <div className="mr-2" style={{ display: 'flex', gap: 20 }}>
                    <Form.Control
                      style={style.input}
                      type="date"
                      value={pickupFrom ?? ''}
                      onChange={(e) => {
                        setPickupFrom(e.target.value);
                        onChange({
                          name: 'ReadyFrom',
                          from: getTimeInMilliseconds(e.target.value),
                          till: getTimeInMilliseconds(pickupTill),
                          type: 'Range',
                        });
                      }}
                      min={new Date().toISOString().split('T')[0]}
                    />
                    <div style={{ marginTop: 10 }}>to</div>
                  </div>
                  <div>
                    <Form.Control
                      style={style.input}
                      type="date"
                      value={pickupTill ?? ''}
                      onChange={(e) => {
                        setPickupTill(e.target.value);
                        onChange({
                          name: 'ReadyFrom',
                          from: getTimeInMilliseconds(pickupFrom),
                          till: getTimeInMilliseconds(e.target.value),
                          type: 'Range',
                        });
                      }}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                </div>
              </Form.Group>

              <div>
                <Form.Group controlId="amountOfVehiclesRange">
                  <Form.Label>Amount of cars</Form.Label>
                  <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                    <div className="mr-2">
                      <Form.Control
                        style={style.inputSmall}
                        type="number"
                        value={amountFrom ?? ''}
                        onChange={(e) => {
                          setAmountFrom(e.target.value);
                          onChange({
                            name: 'AmountOfVehicles',
                            from: e.target.value,
                            till: amountTill,
                            type: 'Range',
                          });
                        }}
                        min={1}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>to</div>
                    <div>
                      <Form.Control
                        style={style.inputSmall}
                        type="number"
                        value={amountTill ?? ''}
                        onChange={(e) => {
                          setAmountTill(e.target.value);
                          onChange({
                            name: 'AmountOfVehicles',
                            from: amountFrom,
                            till: e.target.value,
                            type: 'Range',
                          });
                        }}
                        min={1}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
              {status !== 'Draft' && (
                <div style={{ display: 'flex', gap: 20 }}>
                  <Form.Group controlId="chasisNumber" style={style.input}>
                    <Form.Label>Chassis number</Form.Label>
                    <Form.Control
                      type="text"
                      value={chassisNumber ?? ''}
                      onChange={(e) => {
                        setChassisNumber(e.target.value);
                        onChange({
                          name: 'ChassisNumbersJson',
                          value: e.target.value,
                          type: 'Compare',
                        });
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          onClick();
                        }
                      }}
                      maxLength={40}
                    />
                  </Form.Group>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button style={{ marginTop: 25 }} onClick={() => onClick()} variant="secondary">
                Search
                <SearchLogo className="button-icon-right" />
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default Search;
